<template>
  <div id="PageCareerD">
    <b-container class="pt-100">
      <h5><span>CAREER</span></h5>
      <h2>.NET DEVELOPER</h2>
      <p>
        We are looking for an experienced .NET developer to build .NET projects,
        including back-office and mobile entertainment applications. You will
        work on all phases of the project, beginning with a functional design
        and concluding with implementation and maintenance. Your central task
        will be to design and develop various application layers and work with
        the rest of the team. Your open mind and commitment to building a
        quality product through collaboration and sophisticated design are
        crucial.
      </p>
    </b-container>
    <b-container class="pt-120">
      <h2>RESPONSIBILITIES</h2>
      <ul>
        <li>
          Design, build and maintain a code that is reliable, reusable, and
          efficient
        </li>
        <li>
          Convert use cases and application storyboards into functional
          applications
        </li>
        <li>
          Secure that the final product is optimized, providing excellent
          responsiveness, performance, and the quality
        </li>
        <li>Involve in organizing, maintaining, and automatization of code</li>
        <li>Recognize bugs and bottlenecks and how to address them</li>
      </ul>
    </b-container>

    <b-container class="pt-120">
      <h2>SKILLS</h2>
      <ul>
        <li>Exceptional .NET web framework awareness</li>
        <li>In-depth knowledge of object-oriented programming</li>
        <li>
          MCAD (Microsoft Certified Application Developer) certificate (or the
          ability to obtain it on short notice)
        </li>
        <li>
          Knowledge of RDBMS (MSSQL/Oracle) and familiarity with OOP concepts
          and various architectural patterns and designs
        </li>
        <li>
          The ability to write a code that is readable, clean, and easy to
          maintain
        </li>
        <li>
          Knowledge of fundamental design principles need to build a scalable
          application
        </li>
        <li>The ability to write reusable libraries</li>
        <li>Understanding of concurrency patterns</li>
        <li>Experience with several popular web application frameworks</li>
        <li>Detailed knowledge of various code version tools</li>
        <li>
          The ability to implement unit tests and automated testing platforms
        </li>
      </ul>
    </b-container>
    <AppCareersContact position=".NET Developer"></AppCareersContact>
  </div>
</template>

<script>
import AppCareersContact from "./AppCareersContact";

export default {
  components: {
    AppCareersContact,
  },
};
</script>
