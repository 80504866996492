<template>
  <div id="PageGames">
    <b-container class="description">
      <h5><span>GAMES</span></h5>
      <h2>AMAZING WORLDS</h2>
      <p>
        Only those who love playing games can create terrific games! We develop
        competitive titles which provide thrilling gaming experiences for
        everyone.
      </p>
    </b-container>

    <b-container fluid class="p-0">
      <b-container class="products">
        <b-row>
          <b-col cols="6" sm="6" md="6" lg="6" xl="6">
            <div class="img-box">
              <router-link to="/games/many-real-guns">
                <b-img :src="Animations3" alt="Many Real Guns"></b-img>
                <div class="star">
                  <b-img alt="star" fluid :src="star"></b-img>
                </div>
                <div class="title_div">
                  <h2>MANY REAL GUNS</h2>
                </div>
              </router-link>
            </div>
          </b-col>

          <b-col cols="6" sm="6" md="6" lg="6" xl="6">
            <div class="img-box">
              <router-link to="/games/battlemist">
                <b-img :src="Animations1" alt="Battlemist game"></b-img>
                <div class="title_div">
                  <h2>BATTLEMIST</h2>
                </div>
              </router-link>
            </div>
          </b-col>

          <b-col cols="6" sm="6" md="6" lg="6" xl="6">
            <div class="img-box">
              <router-link to="/games/paladins-of-the-galaxy">
                <b-img :src="Animations4" alt="Paladin of the galaxy"></b-img>
                <div class="title_div">
                  <h2>PALADINS OF THE GALAXY</h2>
                </div>
              </router-link>
            </div>
          </b-col>

          <b-col cols="6" sm="6" md="6" lg="6" xl="6">
            <div class="img-box">
              <router-link to="/games/farming-evolution">
                <b-img :src="Animations2" alt="Famring evolution game"></b-img>
                <div class="title_div">
                  <h2>FARMING EVOLUTION</h2>
                </div>
              </router-link>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      Animations1: require("@/assets/img/games/battlemist.webp"),
      Animations2: require("@/assets/img/games/farming-evolution.webp"),
      Animations3: require("@/assets/img/games/many-real-guns.webp"),
      Animations4: require("@/assets/img/games/paladins-of-the-galaxy.webp"),
      star: require("@/assets/img/star-solid.webp"),
    };
  },
};
</script>
