<template>
  <carousel :responsive="{0:{items:2,nav:false,stagePadding: 20, margin: 10,loop:true,autoplay:true},
                          360:{items:2,nav:false,stagePadding: 50, margin: 10,loop:true,autoplay:true},
                          500:{items:3,nav:true,loop:true,stagePadding: 50, margin: 10, autoplay:true},
                          600:{items:4,nav:true,loop:true,stagePadding: 10, margin: 10, autoplay:true}}">

    <div class="colon">
      <router-link to="/services">
        <b-img :src="WebApp" alt="Web applications"></b-img>
        WEB APPLICATIONS
      </router-link>
    </div>

    <div class="colon">
      <router-link to="/services">
        <b-img :src="Mobile" alt="Mobile solutions"></b-img>
        MOBILE SOLUTIONS
      </router-link>
    </div>

    <div class="colon">
      <router-link to="/services">
        <b-img :src="Quality" alt="Quality Assurance services"></b-img>
        QUALITY ASSURANCE SERVICES
      </router-link>
    </div>

    <div class="colon">
      <router-link to="/services">
        <b-img :src="Windows" alt="Windows applications"></b-img>
        WINDOWS APPLICATIONS
      </router-link>
    </div>

    <div class="colon">
      <router-link to="/services">
        <b-img :src="Gaming" alt="Gaming"></b-img>
        GAMING
      </router-link>
    </div>

    <div class="colon">
      <router-link to="/services">
        <b-img :src="Ux" alt="UX/UI"></b-img>
        UX/UI
      </router-link>
    </div>
    
  </carousel>
</template>

<script>
import carousel from "vue-owl-carousel";
export default {
  name: "carousel1",
  components: { carousel },
  data() {
    return {
      WebApp: require("../assets/img/services/ic_web.webp"),
      Mobile: require("../assets/img/services/ic_mobile.webp"),
      Quality: require("../assets/img/services/ic_quality.webp"),
      Windows: require("../assets/img/services/ic_windows.webp"),
      Gaming: require("../assets/img/services/ic_gaming.webp"),
      Ux: require("../assets/img/services/ic_ux.webp"),
    };
  },
};
</script>