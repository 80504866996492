import Vue from "vue";
import VueRouter from "vue-router";
import home from "@/components/AppHome.vue";
import team from "@/components/AppTeam.vue";
import services from "@/components/AppServices.vue";

import applications from "@/components/AppApplications.vue";
import insight from "@/components/AppApplicationInSight.vue";
import dunavskipark from "@/components/AppApplicationDunavskiPark.vue";
import findloyalty from "@/components/AppApplicationFindLoyalty.vue";
import analytic from "@/components/AppApplicationInAnalytics.vue";
import clinicincloud from "@/components/AppApplicationInClinicInCloud.vue";
import esar from "@/components/AppApplicationInEsar.vue";
import foodordering from "@/components/AppApplicationFoodOrdering.vue";
import rimo from "@/components/AppApplicationInRimo.vue";
import spms from "@/components/AppApplicationInSPMS.vue";
import timetracking from "@/components/AppApplicationInTimeTracking.vue";
import tatoshiwallets from "@/components/AppApplicationTrebleWallet.vue";
import myspot from "@/components/AppApplicationMySpot.vue";
import archon from "@/components/AppApplicationArchon.vue";
import thesuccessplan from "@/components/AppApplicationTheSuccessPlan.vue";
import machinecansee from "@/components/AppApplicationMachineCanSee.vue";

import careers from "@/components/AppCareers.vue";
import careers_dn from "@/components/AppCareersNet.vue";
import careers_unity from "@/components/AppCareersUnity.vue";
import careers_php from "@/components/AppCareersPHP.vue";

import websites from "@/components/AppWebsites.vue";
import coffebocca from "@/components/AppWebsitesCoffe.vue";
import golden from "@/components/AppWebsitesGoldenFive.vue";
import lightsaber from "@/components/AppWebsitesLightsAber.vue";
import webpaladin from "@/components/AppWebsitesPaladins.vue";
import webprometheus from "@/components/AppWebsitesPrometheus.vue";
import webTrulyPublic from "@/components/AppWebsitesTrulyPublic.vue";
import wolfdist from "@/components/AppWebsitesWolfDistribution.vue";
import wolfmedia from "@/components/AppWebsitesWolfMedia.vue";
import DisinfectionDoor from "@/components/AppWebsitesDisinfectionDoor.vue";
import TopTens from "@/components/AppWebsitesTopTens.vue";

import games from "@/components/AppGames.vue";
import manyrealgans from "@/components/AppGameManyRealGuns.vue";
import battlemist from "@/components/AppGameBattlemist.vue";
import farmingevolution from "@/components/AppGameFarmingEvolution.vue";
import paladins from "@/components/AppGamePaladins.vue";

import contactRS from "@/components/AppContactRS";
import contactCH from "@/components/AppContactCH";
import contactNH from "@/components/AppContactNH";
import contactCA from "@/components/AppContactCA";

import cartoons from "@/components/AppCartoons";
import goldenfive from "@/components/AppCartoonsG";
import tomtommy from "@/components/AppCartoonsT";
import captainpepe from "@/components/AppCartoonsC";
import theorganismheroes from "@/components/AppCartoonsH";

import pagenotfond from "@/components/PageNotFound";

import carousel from "@/components/Carousel";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: home
  },
  {
    path: "/team",
    name: "team",
    component: team
  },
  {
    path: "/services",
    name: "services",
    component: services
  },
  {
    path: "/applications",
    name: "applications",
    component: applications
  },
  {
    path: "/applications/dunavski-park",
    name: "dunavskipark",
    component: dunavskipark
  },
  {
    path: "/applications/find-loyalty",
    name: "findloyalty",
    component: findloyalty
  },
  {
    path: "/applications/ni-analytics",
    name: "nianalytic",
    component: analytic
  },
  {
    path: "/applications/archon-compass",
    name: "archon",
    component: archon
  },
  {
    path: "/applications/clinic-in-cloud",
    name: "clinicincloud",
    component: clinicincloud
  },
  {
    path: "/applications/esar",
    name: "esar",
    component: esar
  },
  {
    path: "/applications/food-ordering",
    name: "foodordering",
    component: foodordering
  },
  {
    path: "/applications/rimo",
    name: "rimo",
    component: rimo
  },
  {
    path: "/spms",
    name: "spms",
    component: spms
  },
  {
    path: "/applications/time-tracking",
    name: "timetracking",
    component: timetracking
  },
  {
    path: "/applications/my-spot",
    name: "myspot",
    component: myspot
  },
  {
    path: "/applications/tatoshi-wallet",
    name: "tatoshiwallets",
    component: tatoshiwallets
  },
  {
    path: "/applications/the-success-plan",
    name: "TheSuccessPlan",
    component: thesuccessplan
  },
  {
    path: "/applications/machine-can-see",
    name: "MachineCanSee",
    component: machinecansee
  },
  {
    path: "/insight",
    name: "insight",
    component: insight
  },
  {
    path: "/careers-net-developer",
    name: "careers-dn",
    component: careers_dn
  },

  {
    path: "/careers-unity-developer",
    name: "careers-unity",
    component: careers_unity
  },
  {
    path: "/careers-php-developer",
    name: "careers-php",
    component: careers_php
  },
  {
    path: "/careers",
    name: "careers",
    component: careers
  },

  {
    path: "/contact-rs",
    name: "contactrs",
    component: contactRS
  },
  {
    path: "/contact-ch",
    name: "contact-ch",
    component: contactCH
  },
  {
    path: "/contact-nl",
    name: "contactNH",
    component: contactNH
  },
  {
    path: "/contact-ca",
    name: "contact-ca",
    component: contactCA
  },
  {
    path: "/cartoons",
    name: "cartoons",
    component: cartoons
  },
  {
    path: "/cartoons/the-organism-heroes",
    name: "the-organism-heroes",
    component: theorganismheroes
  },
  {
    path: "/cartoons/golden-five",
    name: "goldenfive",
    component: goldenfive
  },
  {
    path: "/cartoons/tom-and-tommy",
    name: "tomtommy",
    component: tomtommy
  },
  {
    path: "/cartoons/captain-pepe",
    name: "captainpepe",
    component: captainpepe
  },
  {
    path: "/websites/golden-five",
    name: "golden",
    component: golden
  },
  {
    path: "/websites/coffee-beans",
    name: "coffeebeans",
    component: coffebocca
  },
  {
    path: "/websites/block-games",
    name: "blockgames",
    component: lightsaber
  },
  {
    path: "/websites/paladins-of-the-galaxy",
    name: "webpaladins",
    component: webpaladin
  },
  {
    path: "/websites/prometheus",
    name: "prometheus",
    component: webprometheus
  },
  {
    path: "/websites/truly-public",
    name: "trulypublic",
    component: webTrulyPublic
  },
  {
    path: "/websites/top-tens",
    name: "TopTens",
    component: TopTens
  },
  {
    path: "/websites/disinfection-door",
    name: "DisinfectionDoor",
    component: DisinfectionDoor
  },
  {
    path: "/websites/wolf-distribution",
    name: "wolfdist",
    component: wolfdist
  },
  {
    path: "/websites/wolf-media",
    name: "wolfmedia",
    component: wolfmedia
  },
  {
    path: "/websites",
    name: "websites",
    component: websites
  },
  {
    path: "/games",
    name: "games",
    component: games
  },
  {
    path: "/games/many-real-guns",
    name: "ManyRealGans",
    component: manyrealgans
  },
  {
    path: "/games/paladins-of-the-galaxy",
    name: "paladins",
    component: paladins
  },
  {
    path: "/games/farming-evolution",
    name: "farmingevolution",
    component: farmingevolution
  },
  {
    path: "/games/battlemist",
    name: "battlemist",
    component: battlemist
  },
  {
    path: "*",
    name: "page-not-found",
    component: pagenotfond
  },
  {
    path: "/carousel",
    name: "carousel",
    component: carousel
  },
  {
    path: "*",
    redirect: "/page-not-found"
  }
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  base: process.env.BASE_URL,
  routes
});

export default router;
