<template>
  <div id="PageServices">
    <b-container>
      <h5><span>SERVICES</span></h5>
      <h2>OUR MAIN SERVICES</h2>
      <p class="text-just">
        At NS Web Development, we do things differently. As a company providing
        key software development services, we focus on helping our clients build
        a successful business in enterprise web and mobile domains.
      </p>
    </b-container>
    <b-container class="mt-100">
      <b-row>
        <b-col cols sm="12" md="4" lg="4" xl="4">
          <img :src="WebApp" alt="Development of web applications" />
          <h4>WEB APPLICATIONS</h4>
          <p>
            We use agile methodologies & the latest technology to develop
            superior applications and take your business to the next level.
          </p>
        </b-col>
        <b-col cols sm="12" md="4" lg="4" xl="4">
          <img :src="Mobile" alt="Development of mobile solutions" />
          <h4>MOBILE SOLUTIONS</h4>
          <p>
            We build terrific, fully customized apps for iOS and Android. From
            idea conception to product launch & maintenance, we cover the entire
            development process.
          </p>
        </b-col>
        <b-col cols sm="12" md="4" lg="4" xl="4">
          <img :src="Quality" alt="Quality" />
          <h4>QUALITY ASSURANCE SERVICES</h4>
          <p>
            Our QA team can help catch bugs & errors in your applications early
            on. They can also help certify your product, test your app on the
            actual market, and implement the highest quality standards to roll
            out the perfect app.
          </p>
        </b-col>
        <b-col cols sm="12" md="4" lg="4" xl="4">
          <img :src="Windows" alt="Windows applications" />
          <h4>WINDOWS APPLICATIONS</h4>
          <p>
            We help build Windows-based applications that streamline your
            business processes and get you closer to achieving your goals.
          </p>
        </b-col>
        <b-col cols sm="12" md="4" lg="4" xl="4">
          <img :src="Gaming" alt="Development of mobile gamings" />
          <h4>GAMING</h4>
          <p>
            Only people who like to play games can create amazing games! We
            develop competitive titles which provide thrilling gaming
            experiences for everyone.
          </p>
        </b-col>
        <b-col cols sm="12" md="4" lg="4" xl="4">
          <img :src="Ux" alt="UX/UI design" />
          <h4>UX</h4>
          <p>
            All the products we make are created for users and not for our
            satisfaction. That is why our developers and graphic designers
            always give special attention to the user experience, which is later
            tested and iterated to our clients and users' needs.
          </p>
        </b-col>
      </b-row>
    </b-container>

    <b-container class="mb-100 mt-100">
      <h2>CONSULTANCY</h2>
      <p class="text-center">
        Business Requirements
        <br />
        Customer Workshops<br />
        Digital Strategy
        <br />
        Technology Strategy
        <br />
        Content Migration Strategy<br />
      </p>
    </b-container>
    <b-container class="mb-100">
      <h2>PROJECTS</h2>
      <p class="text-center">
        System Architecture<br />
        Design and Front-end development<br />
        Build and Implement<br />
        QA and Testing
      </p>
    </b-container>
    <b-container class="mb-100 pb-4">
      <h2>SUPPORT</h2>
      <p class="text-center">
        Application Lifecycle Management<br />
        Cloud hosting<br />
        Tailored support models<br />
        Content Management
      </p>
    </b-container>
    <div class="technologies">
      <div class="container_1920">
        <h2>TECHNOLOGIES WE USE</h2>
        <b-container class="services">
          <div class="serv_img">
            <b-img alt=".net logo" :src="net_color" class="img_color"></b-img>
          </div>
          <div class="serv_img">
            <b-img alt="php logo" :src="php_color" class="img_color"></b-img>
          </div>
          <div class="serv_img">
            <b-img
              alt="android logo"
              :src="android_color"
              class="img_color"
            ></b-img>
          </div>
          <div class="serv_img">
            <b-img alt="ios logo" :src="ios_color" class="img_color"></b-img>
          </div>
          <div class="serv_img">
            <b-img
              alt="unity logo"
              :src="unity_color"
              class="img_color"
            ></b-img>
          </div>
          <div class="serv_img">
            <b-img
              alt="angular logo"
              :src="angular_color"
              class="img_color"
            ></b-img>
          </div>
          <div class="serv_img">
            <b-img alt="vue logo" :src="vue_color" class="img_color"></b-img>
          </div>
          <div class="serv_img">
            <b-img
              alt="react logo"
              :src="react_color"
              class="img_color"
            ></b-img>
          </div>
          <div class="serv_img">
            <b-img
              alt="flutter logo"
              :src="flutter_color"
              class="img_color"
            ></b-img>
          </div>
          <div class="serv_img">
            <b-img
              alt="laravel logo"
              :src="laravel_color"
              class="img_color"
            ></b-img>
          </div>
        </b-container>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      WebApp: require("../assets/img/services/ic_web-colour.webp"),
      Mobile: require("../assets/img/services/ic_mobile-colour.webp"),
      Quality: require("../assets/img/services/ic_quality-colour.webp"),
      Windows: require("../assets/img/services/ic_windows-colour.webp"),
      Gaming: require("../assets/img/services/ic_gaming-colour.webp"),
      Ux: require("../assets/img/services/ic_ux-colour.webp"),

      android_color: require("../assets/img/Technologies/android-logo.webp"),
      angular_color: require("../assets/img/Technologies/angular-logo.webp"),
      flutter_color: require("../assets/img/Technologies/flutter-logo.webp"),
      ios_color: require("../assets/img/Technologies/ios-logo.webp"),
      laravel_color: require("../assets/img/Technologies/laravel-logo.webp"),
      net_color: require("../assets/img/Technologies/net-logo.webp"),
      php_color: require("../assets/img/Technologies/php-logo.webp"),
      react_color: require("../assets/img/Technologies/react-logo.webp"),
      unity_color: require("../assets/img/Technologies/unity-logo.webp"),
      vue_color: require("../assets/img/Technologies/vue-logo.webp"),
    };
  },
};
</script>
