<template>
  <div id="ApplicationD">
    <b-container class="description">
      <h5><span>APPLICATION</span></h5>
      <h2>FIND LOYALTY</h2>
      <p>
        Find Loyalty is an Android application that allows you to download all your favorite loyalty and membership cards to 
        your phone and use them anytime at all sales outlets, clubs, and online! With the Find Loyalty Android application, 
        you can quickly load your favorite loyalty and membership cards on your phone so that you can use them anywhere and anytime. 
        The Find Loyalty application currently supports 40+ different loyalty programs and an unlimited number of member card types.
      </p>
    </b-container>

    <b-container class="stores">
      <b-container>
        <!-- <div class="store">
          <a target="_blank" href="" class="btn">WEBSITE</a>
        </div> -->
      </b-container>
    </b-container>

    <b-container fluid class="details">
      <div class="container_1920">
      <b-row>
        <b-col cols="12" sm="12" md="12" xl="6" lg="6">
          <b-img fluid class="img_w" :src="phone1" alt="Find Loyalty"></b-img>
        </b-col>
        <b-col cols="12" sm="12" md="12" xl="6" lg="6" class="detail">
          <b-container>
            <h5><span>CLIENT</span></h5>
            <p>Simula, Serbia</p>
          </b-container>
          <b-container>
            <h5><span>SERVICES</span></h5>
            <p>Software Development, Mobile Development, SEO</p>
          </b-container>
          <b-container>
            <h5><span>TECHNOLOGIES</span></h5>
            <p>C#, HTML & CSS, XML, Java, Android</p>
          </b-container>
          <b-container>
            <h5><span>YEAR</span></h5>
            <p>2019</p>
          </b-container>
        </b-col>
      </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phone1: require("@/assets/img/aplication/find-loyalty.webp"),
      appstory: require("@/assets/img/AppStore.webp"),
      playstory: require("@/assets/img/GooglePlay.webp")
    };
  }
};
</script>
