<template>
  <div id="ApplicationD">
    <b-container class="description">
      <h5><span>APPLICATION</span></h5>
      <h2>CLINIC IN CLOUD</h2>
      <p>
        Clinic in Cloud offers practitioners a secure, web-based, real-time software platform to manage all aspects of a clinic,
         including scheduling, billing, and patient care. Part of this platform is the Clinic in Cloud Patient app that offers 
         users a list of doctors, available appointments, and secure access to personal health information.
      </p>
    </b-container>

    <div class="suportby">
      <h2>SUPPORTED BY</h2>
      <div class="logo-img">
        <a target="_blank" rel="noreferrer" href="http://www.inovacionifond.rs/">
          <b-img :src="inovation" alt="Inovacioni fond logo"></b-img>
        </a>
      </div>
    </div>

    <b-container class="stores">
      <b-container>
        <div class="store">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.youtube.com/watch?v=bQ_TjxJwQBs"
            class="btn"
            >DEMO VIDEO</a
          >
        </div>
        <div class="store">
          <a target="_blank" rel="noreferrer" href="https://www.clinicincloud.com/" class="btn"
            >WEBSITE</a
          >
        </div>
      </b-container>
    </b-container>

    <b-container fluid class="details">
      <div class="container_1920">
        <b-row>
          <b-col cols="12" sm="12" md="12" xl="6" lg="6">
            <div class="star">
                  <b-img alt="Time tracker" fluid :src="star"></b-img>
                </div>
            <b-img fluid class="img_w" :src="phone1" alt="Clinic In Cloud"></b-img>
          </b-col>
          <b-col cols="12" sm="12" md="12" xl="6" lg="6" class="detail">
            <b-container>
              <h5><span>CLIENT</span></h5>
              <p>NS Web Development, Serbia</p>
            </b-container>
            <b-container>
              <h5><span>SERVICES</span></h5>
              <p>Software Development, Mobile Development, SEO</p>
            </b-container>
            <b-container>
              <h5><span>TECHNOLOGIES</span></h5>
              <p>C#, HTML&CSS, XML, Java,Andorid</p>
            </b-container>
            <b-container>
              <h5><span>YEAR</span></h5>
              <p>2014</p>
            </b-container>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phone1: require("@/assets/img/aplication/clinic-in-cloud.webp"),
      appstory: require("@/assets/img/AppStore.webp"),
      playstory: require("@/assets/img/GooglePlay.webp"),
      inovation: require("@/assets/img/Logos/innovation-fund-logo.webp"),
      star: require("@/assets/img/star-solid.webp"),
    };
  },
};
</script>
