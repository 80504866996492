<template>
  <div id="PageCartoonsDetails">
    <b-container class="description">
      <h5><span>CARTOON</span></h5>
      <h2>CAPTAIN PEPE</h2>
      <p>
        There's one cute little turtle that lives in a lush green field. She
        lives peacefully in her small home, takes care of her garden, and waters
        her fruits and vegetables. She takes care of all this land by herself,
        but her life would be monotonous if it weren't for Captain Pepe. Captain
        Pepe lives high up on the peak of a rocky mountain, on his vantage
        point. His only life goal is to feed himself, but having had enough
        vegetables and fruit, he decides to catch his prey, the cute green
        turtle Lilly. He's likable, clumsy, and persistent as he creates comical
        plans that always end up working against him. Watch the mini-series and
        find out if Captain Pepe will ever be able to catch Lilly, or are they
        eventually going to end up as friends. Have a good laugh, and enjoy the
        show.
      </p>
    </b-container>

    <b-container class="stores">
      <b-container>
        <div class="store">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.youtube.com/watch?v=UJ8TymKUA3U&ab_channel=NSWebDevelopment"
            class="btn"
            >TRAILER</a
          >
        </div>
        <div class="store">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://wolfmedia.media/"
            class="btn"
            >WEBSITE</a
          >
        </div>
      </b-container>
    </b-container>

    <b-container fluid class="details">
      <div class="container_1920">
        <b-row>
          <b-col cols="12" sm="12" md="12" lg="6" xl="6" p-sm="0">
            <b-img
              fluid
              class="img_w"
              :src="capteinpepe"
              alt="Captein Pepe"
            ></b-img>
          </b-col>
          <b-col cols="12" sm="12" md="12" lg="6" xl="6" class="detail">
            <b-container>
              <h5><span>PRODUCTION</span></h5>
              <p>NSWD</p>
            </b-container>
            <b-container>
              <h5><span>GENRE</span></h5>
              <p>Carton TV series</p>
            </b-container>
            <b-container>
              <h5><span>DURATION</span></h5>
              <p>52 ep x 2.5min</p>
            </b-container>
            <b-container>
              <h5><span>YEAR</span></h5>
              <p>2017</p>
            </b-container>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      capteinpepe: require("@/assets/img/Cartoons/Captain-Pepe.webp"),
    };
  },
};
</script>
