<template>
  <div id="WebsiteDetails">
    <b-container class="description">
      <h5><span>WEBSITE</span></h5>
      <h2>WOLF MEDIA</h2>
      <p>
        Wolf Media is an animation and production company focusing on creating
        original content. However, it is open to cooperate with filmmakers,
        scriptwriters, or other development companies. Key focus groups are
        preschool, and school kids as the cartoons are non-violent and
        educational. Wolf Media is a very young and highly motivated team
        focused on delivering their vision to the world. Novi Sad is a
        university center that includes Education Program for Animation,
        creating an exceptional expansion opportunity.
      </p>
      <p>
        Current projects include multiple animated shows such as Tom & Tommy,
        Golden Five, The Organism Heroes, Lord of the River, or Captain Pepe.
        However, new content is continuously in development. Explore the site to
        learn more about the team and their innovative products.
      </p>
    </b-container>

    <b-container class="stores">
      <b-container>
        <div class="store">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://wolfmedia.media/"
            class="btn"
            >WEBSITE</a
          >
        </div>
      </b-container>
    </b-container>

    <b-container fluid class="details">
      <div class="container_1920">
        <b-row>
          <b-col cols="12" sm="12" md="12" xl="6" lg="6">
            <b-img fluid class="img_w" :src="phone1" alt="Wolf Media"></b-img>
          </b-col>
          <b-col cols="12" sm="12" md="12" xl="6" lg="6" class="detail">
            <b-container>
              <h5><span>CLIENT</span></h5>
              <p>NSWD</p>
            </b-container>
            <b-container>
              <h5><span>SERVICE</span></h5>
              <p>Software Development, Mobile Development, SEO</p>
            </b-container>
            <b-container>
              <h5><span>TECHNOLOGIES</span></h5>
              <p>C#, HTML & CSS, XML, Java, Android</p>
            </b-container>
            <b-container>
              <h5><span>YEAR</span></h5>
              <p>2019</p>
            </b-container>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phone1: require("@/assets/img/web/wolf-media.webp"),
    };
  },
};
</script>
