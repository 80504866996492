<template>
  <div id="GameDetails">
    <b-container class="description">
      <h5><span>GAME</span></h5>
      <h2>FARMING EVOLUTION</h2>
      <p>
        Farming Evolution is the latest farm simulator 3D game that provides the
        best experience for everyone who wants to live a farmer's life. Operate
        heavy machinery throughout your farmland and tend to your land and
        plants. All the farm machines simulate real-life tractors, combine
        harvesters, cultivators, sowing machines, fertilize spreaders, slurry
        tanks, forwarders, and mowers.
      </p>
    </b-container>

    <b-container class="stores">
      <b-container>
        <div class="store_img">
          <div class="store">
            <a
              
              rel="noreferrer"
              href=""
            >
              <b-img
                :src="appstore"
                class="img-fluid"
                alt="Appstory logo"
              ></b-img>
            </a>
          </div>
        </div>
      </b-container>
    </b-container>

    <b-container fluid class="details">
      <div class="container_1920">
        <b-row>
          <b-col cols="12" sm="12" md="12" lg="6" xl="6" p-sm="0">
            <b-img fluid :src="farming" alt="Farming Evolution"></b-img>
          </b-col>
          <b-col cols="12" sm="12" md="12" lg="6" xl="6" class="detail">
            <b-container>
              <h5><span>CLIENT</span></h5>
              <p>Torus Hold</p>
            </b-container>
            <b-container>
              <h5><span>GENRE</span></h5>
              <p>Mobile strategy game</p>
            </b-container>
            <b-container>
              <h5><span>SERVICES</span></h5>
              <p>Android, iOS and WebGL Development</p>
            </b-container>
            <b-container>
              <h5><span>TECHNOLOGIES</span></h5>
              <p>C#, Unit 2D/3D</p>
            </b-container>
            <b-container>
              <h5><span>YEAR</span></h5>
              <p>2015</p>
            </b-container>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      farming: require("@/assets/img/games/farming-evolution.webp"),
      appstore: require("@/assets/img/AppStore.webp"),
      playstore: require("@/assets/img/GooglePlay.webp"),
    };
  },
};
</script>
