<template>
  <div id="ApplicationD">
    <b-container class="description">
      <h5><span>APPLICATION</span></h5>
      <h2>ARCHON COMPASS</h2>
      <p>
        Optimizing your workflow is critical for the viability of any business. 
        Creating a centralized platform to facilitate an organized and structured 
        system is paramount. This proprietary management system allows your entire 
        team full access to some compelling features at any time, on any media 
        platform for your convenience.
      </p>
    </b-container>

    <b-container class="stores">
      <b-row>
        <b-col sm="8" md="4" xl="2" offset-sm="2" offset-md="4" offset-xl="5">
          
        </b-col>
      </b-row>
      <b-container>
        <div class="store">
          <a target="_blank" rel="noreferrer" href="https://archonenergy.com/" class="btn">WEBSITE</a>
        </div>
      </b-container>
    </b-container>

    <b-container fluid class="details">
      <div class="container_1920">
      <b-row>
        <b-col cols="12" sm="12" md="12" xl="6" lg="6">
          <b-img fluid class="img_w" :src="phone1" alt="Archon Compass"></b-img>
        </b-col>
        <b-col cols="12" sm="12" md="12" xl="6" lg="6" class="detail">
          <b-container>
            <h5><span>CLIENT</span></h5>
            <p>Archon Energy Solutions, California, USA</p>
          </b-container>
          <b-container>
            <h5><span>SERVICES</span></h5>
            <p>Software Development, SEO</p>
          </b-container>
          <b-container>
            <h5><span>TECHNOLOGIES</span></h5>
            <p>PHP Laravel, Angular, MariaDB</p>
          </b-container>
          <b-container>
            <h5><span>YEAR</span></h5>
            <p>2020</p>
          </b-container>
        </b-col>
      </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phone1: require("@/assets/img/aplication/Archon-Compass.webp"),
      appstory: require("@/assets/img/AppStore.webp"),
      playstory: require("@/assets/img/GooglePlay.webp"),
    };
  },
};
</script>
