<template>
  <div id="ApplicationD">
    <b-container class="description">
      <h5><span>APPLICATION</span></h5>
      <h2>RIMO</h2>
      <p>
        Rimo application eases the flat acceptance signing! Update the app with data from the real estate 
        management software RIMO R4 and run the flat acceptance on the iPad. Initially made for the Swiis 
        and German markets, Rimo helps real estate agents simplify the rent or sell process. It shortens 
        the procedure between the agent, the renter/seller, and the buyer, with all the data always available 
        for the app users. There is no extra paperwork as it's all in the app, on the server. Therefore,
         any agent anywhere in the world can work for you. RIMO allows you to select who will be present 
         at the meeting and to sign the contract on the spot. You also have an option to add pictures.
      </p>
    </b-container>

    <b-container class="stores">
      <b-container>
        <div class="store">
          <a target="_blank" rel="noreferrer" href="https://rimosign.ch/" class="btn">WEBSITE</a>
        </div>
      </b-container>
      <b-container>
        <div class="store_img">
        <div class="store">
          <a target="_blank" rel="noreferrer" href="https://apps.apple.com/de/app/id923389707" class="store">
            <b-img :src="appstory" alt="Appstory"></b-img>
          </a>
        </div>
        </div>
      </b-container>
    </b-container>

    <b-container fluid class="details">
      <div class="container_1920">
      <b-row>
        <b-col cols="12" sm="12" md="12" xl="6" lg="6">
          <b-img fluid class="img_w" :src="phone1" alt="Rimo"></b-img>
        </b-col>
        <b-col cols="12" sm="12" md="12" xl="6" lg="6" class="detail">
          <b-container>
            <h5><span>CLIENT</span></h5>
            <p>eXtenso IT-Services AG, Switzerland</p>
          </b-container>
          <b-container>
            <h5><span>SERVICES</span></h5>
            <p>Software Development, Mobile Development, SEO</p>
          </b-container>
          <b-container>
            <h5><span>TECHNOLOGIES</span></h5>
            <p>C#, HTML&CSS, XML, Java,Andorid</p>
          </b-container>
          <b-container>
            <h5><span>YEAR</span></h5>
            <p>2014</p>
          </b-container>
        </b-col>
      </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phone1: require("@/assets/img/aplication/rimo.webp"),
      appstory: require("@/assets/img/AppStore.webp"),
      playstory: require("@/assets/img/GooglePlay.webp")
    };
  }
};
</script>
