<template>
  <b-container fluid id="ApplayPosition">
    <div class="CareerContact">
      <h2>APPLY FOR POSITION</h2>
      <form @submit.prevent="send">
        <b-form-group>
          <b-form-input
            type="text"
            name="fname"
            v-model="form.fname"
            v-validate="'required|alpha_spaces'"
            :class="{ 'is-invalid': errors.first('fname') }"
            placeholder="Full name*"
          ></b-form-input>
          <b-form-invalid-feedback id="fnameF"
            >This field is required.</b-form-invalid-feedback
          >
        </b-form-group>
        <b-form-group>
          <b-form-input
            type="text"
            name="email"
            v-model="form.email"
            v-validate="'required|email'"
            :class="{ 'is-invalid': errors.first('email') }"
            placeholder="Email*"
          ></b-form-input>
          <b-form-invalid-feedback
            >This field is required.</b-form-invalid-feedback
          >
        </b-form-group>
        
        <b-form-group class="upload-file">
          <b-row class="btn-file-div">
            <b-col cols="12" sm="12" md="6" lg="6" xl="6">
              <b-form-group>
                <span class="btn-file"
                  >{{ cvButtons
                  }}<input
                    type="file"
                    id="cv_file"
                    ref="cv_File"
                    v-on:change="cvFileUpload()"
                  />
                </span>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="12" md="6" lg="6" xl="6">
              <b-form-group>
                <span v-show="cover" class="btn-file btn-file-r"
                  >ATTACH COVER LETTER<input
                    type="file"
                    id="cover_file"
                    ref="cover_File"
                    v-on:change="coverFileUpload()"
                  />
                </span>
                <span
                  class="btn-file btn-file-r"
                  v-show="!cover"
                  @click="coverFileUpload()"
                  >REMOVE COVER LETTER</span
                >
              </b-form-group>
            </b-col>
          </b-row>
        </b-form-group>

        <b-form-group>
          <b-form-textarea
            v-model="form.message"
            placeholder="Link to your work, projects, videos, code etc."
            rows="5"
            max-rows="5"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group>
          <div class="btn btn_bottom">
            <b-button
              :class="{ 'btn-a': !terms, 'btn-b': terms }"
              type="submit"
              variant="primary"
              >APPLY</b-button
            >
          </div>
        </b-form-group>
      </form>
    </div>
  </b-container>
</template>
<script>
export default {
  props: {
    position: String
  },
  data() {
    return {
      form: {
        fname: "",
        email: "",
        message: "",
      },
      shower: true,
      showerM: false,
      cvFile: "",
      coverFile: "",
      terms: true,
      cover: true,
      cvButtons: "ATTACH CV*",
      status: "",
      cololor: null,
    };
  },
  methods: {
    cvFileUpload() {
      this.cvFile = this.$refs.cv_File.files[0];

      if (this.cvFile === "") {
        this.terms = true;
        this.cvButtons = "ATTACH CV*";
        this.buttonColor = "class='btn-b'";
      } else {
        this.terms = false;
        this.cvButtons = this.cvFile.name;
        this.buttonColor = "class='btn-b'";
      }
    },
    coverFileUpload() {
      if (this.cover) {
        this.coverFile = this.$refs.cover_File.files[0];
        this.cover = false;
      } else {
        this.coverFile = "";
        this.cover = true;
      }
    },
    send() {
      let formData = new FormData();

      if (this.cvFile != "") {
        formData.append("cvFile", this.cvFile);
      }
      if (this.coverFile != "") {
        formData.append("coverFile", this.coverFile);
      }
      formData.append("fname", this.form.fname);
      formData.append("email", this.form.email);
      formData.append("message", this.form.message);
      formData.append("position", this.position);

      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$recaptcha("login").then((token) => {
            if (token) {
              this.axios
                .post(__dirname + "careers.php", formData)
                .then((response) => {
                  console.log(response);
                  this.shower = true;
                  this.showerM = true;
                  this.clierForm();
                  this.$nextTick(() => {
                    this.$validator.reset();
                  });
                })
                .catch((error) => {
                  console.log(error);
                });
              return;
            }
          });
        } else {
          this.showerM = false;
          this.shower = false;
        }
      });
    },
    clierForm() {
      this.form.fname = "";
      this.form.email = "";
      this.form.message = "";
      this.cvFile = "";
      this.coverFile = "";
      this.cvButtons = "ATTACH CV*";
      this.cover = true;
    },
  },
};
</script>