<template>
  <div id="ApplicationD">
    <b-container class="description">
      <h5><span>APPLICATION</span></h5>
      <h2>IN SIGN</h2>
      <p>
        InSight is an application which provides infrastructure for
        self-monitoring and improvement.Application user can record his feelings
        and thoughts as well as events and actions in which he had participated
        in, through three categories – My Time, My Finances and My Mood. Through
        data visualization of those entered records user can easily analyze his
        habits, capacities, correlation of his actions and understand what
        affects his mood and happiness. Some of Insight App features are: Easy
        input of each category based on user friendly interface Multiple options
        for visual display using filters and queries 24/7 graphical view – Graph
        which gives clear comparative display of all categories in time Weekly
        and monthly reports with interesting data visualization form
      </p>
    </b-container>

    <b-container class="stores">
      <b-container>
        <div class="store">
          <a target="_blank" rel="noreferrer" href="#" class="btn">WEBSITE</a>
        </div>
      </b-container>
      <b-container>
        <div class="store">
          <a target="_blank" rel="noreferrer" href="#" class="store">
            <b-img :src="appstory" alt="Appstory"></b-img>
          </a>
        </div>
        <div class="store">
          <a target="_blank" rel="noreferrer" href="#" class="store">
            <b-img :src="playstory" alt="Playstory"></b-img>
          </a> 
        </div>
      </b-container>
    </b-container>

    <b-container fluid class="details">
      <div class="container_1920">
      <b-row>
        <b-col cols="12" sm="12" md="12" xl="6" lg="6">
          <b-img fluid class="img_w" :src="phone1" alt="In Sight"></b-img>
        </b-col>
        <b-col cols="12" sm="12" md="12" xl="6" lg="6" class="detail">
          <b-container>
            <h5><span>CLIENT</span></h5>
            <p>NSWD</p>
          </b-container>
          <b-container>
            <h5><span>SERVICES</span></h5>
            <p>Software Development, Mobile Development, SEO</p>
          </b-container>
          <b-container>
            <h5><span>TECHNOLOGIES</span></h5>
            <p>C#, HTML&CSS, XML, Java,Andorid</p>
          </b-container>
          <b-container>
            <h5><span>YEAR</span></h5>
            <p>2015</p>
          </b-container>
        </b-col>
      </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phone1: require("@/assets/img/aplication/in-sight.webp"),
      appstory: require("@/assets/img/AppStore.webp"),
      playstory: require("@/assets/img/GooglePlay.webp")
    };
  }
};
</script>
