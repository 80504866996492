<template>
  <div id="PageOurTeam">
    <b-container>
      <h5><span>TEAM</span></h5>
      <h2>GET TO KNOW US</h2>
      <p>
        We bring our experiences, background, and perspectives together to
        collaborate and achieve great things. Our people are empowered to
        innovate and let our skills take us in unexpected and exciting
        directions. Most importantly, we can be ourselves at work, and we get
        things right.
      </p>
    </b-container>
    <b-container fluid>
      <b-container>
        <b-row>
          <b-col cols="6" md="3" lg="3" xl="2" class="p-0">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/srdjan-vukmirovic-a8b2632a/"
            >
              <img
                :src="SrdjanVukmirovic"
                class="img-fluid"
                alt="Srdjan Vukmirovic"
              />
              <div class="scwr">
                <h3>SRDJAN VUKMIROVIC</h3>
                <p>Founder & Owner</p>
              </div>
            </a>
          </b-col>

          <b-col cols="6" md="3" lg="3" xl="2" class="p-0">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/ana-vukmirovi%C4%87-7a899a107/"
            >
              <img
                :src="AnaVukmirovic"
                class="img-fluid"
                alt="Ana Vukmirovic"
              />
              <div class="scwr">
                <h3>ANA VUKMIROVIC</h3>
                <p>CEO</p>
              </div>
            </a>
          </b-col>

          <b-col cols="6" md="3" lg="3" xl="2" class="p-0">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/dejan-vukmirovic-66554880/"
            >
              <img
                :src="DejanVukmirovic"
                class="img-fluid"
                alt="Dejan Vukmirovic"
              />
              <div>
                <h3>DEJAN VUKMIROVIC</h3>
                <p>CIO</p>
              </div>
            </a>
          </b-col>

          <b-col cols="6" md="3" lg="3" xl="2" class="p-0">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/blagoje-bulajic-973475b9/"
            >
              <img
                :src="BlagojeBulajic"
                class="img-fluid"
                alt="Blagoje Bulajic"
              />
              <div>
                <h3>BLAGOJE BULAJIC</h3>
                <p>Аrt Director</p>
              </div>
            </a>
          </b-col>
          <b-col cols="6" md="3" lg="3" xl="2" class="p-0">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/boris-andrejic-918997143/"
            >
              <img
                :src="BorisAndrejic"
                class="img-fluid"
                alt="Boris Andrejic"
              />
              <div>
                <h3>BORIS ANDREJIC</h3>
                <p>3D Generalist</p>
              </div>
            </a>
          </b-col>
          
          <b-col cols="6" md="3" lg="3" xl="2" class="p-0">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/nikola-savic-03a556153"
            >
              <img
                :src="NikolaSavic"
                class="img-fluid"
                alt="Nikola Savic"
              />
              <div>
                <h3>NIKOLA SAVIC</h3>
                <p>3D Artist</p>
              </div>
            </a>
          </b-col>

          <b-col cols="6" md="3" lg="3" xl="2" class="p-0">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/jovica-%C4%8Donki%C4%87-69812580/"
            >
              <img :src="JovicaConkic" class="img-fluid" alt="Jovica Conkic" />
              <div>
                <h3>JOVICA CONKIC</h3>
                <p>Full Stack Developer</p>
              </div>
            </a>
          </b-col>

          <b-col cols="6" md="3" lg="3" xl="2" class="p-0">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/miljko-miljkovic-a96b44113/"
            >
              <img
                :src="MiljkoMiljkovic"
                class="img-fluid"
                alt="Miljko Miljkovic"
              />
              <div>
                <h3>MILJKO MILJKOVIC</h3>
                <p>Front-end Developer</p>
              </div>
            </a>
          </b-col>

          <b-col cols="6" md="3" lg="3" xl="2" class="p-0">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/milo%C5%A1-markovi%C4%87-4a8b27224/"
            >
              <img
                :src="MilosMarkovic"
                class="img-fluid"
                alt="Milos Markovic"
              />
              <div>
                <h3>MILOS MARKOVIC</h3>
                <p>Front-end Developer</p>
              </div>
            </a>
          </b-col>

          <b-col cols="6" md="3" lg="3" xl="2" class="p-0">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/company/ns-web-development-doo-novi-sad"
            >
              <img
                :src="KatarinaMajor"
                class="img-fluid"
                alt="Katarina Major"
              />
              <div>
                <h3>KATARINA MAJOR</h3>
                <p>Front-end Developer</p>
              </div>
            </a>
          </b-col>

          <b-col cols="6" md="3" lg="3" xl="2" class="p-0">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/andrija-sagi/"
            >
              <img :src="AndrijaSagi" class="img-fluid" alt="Andrija Sagi" />
              <div>
                <h3>ANDRIJA SAGI</h3>
                <p>PHP Backend Developer</p>
              </div>
            </a>
          </b-col>

          <b-col cols="6" md="3" lg="3" xl="2" class="p-0">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/company/ns-web-development-doo-novi-sad"
            >
              <img
                :src="MartinaMarkovic"
                class="img-fluid"
                alt="Martina Lacarac"
              />
              <div>
                <h3>MARTINA LACARAC</h3>
                <p>PHP Backend Developer</p>
              </div>
            </a>
          </b-col>

          <b-col cols="6" md="3" lg="3" xl="2" class="p-0">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/zoran-damjanov-11870059/"
            >
              <img
                :src="ZoranDamjanov"
                class="img-fluid"
                alt="Zoran Damjanov"
              />
              <div>
                <h3>ZORAN DAMJANOV</h3>
                <p>PHP Backend Developer</p>
              </div>
            </a>
          </b-col>

           <b-col
            cols="6"
            md="3"
            lg="3"
            xl="2"
            class="p-0"
          >
            <img :src="Quote2" class="img-fluid" alt="Alone we can do so little" />
          </b-col>

          <b-col cols="6" md="3" lg="3" xl="2" class="p-0">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/nemanja-simiki%C4%87-56b071154/"
            >
              <img
                :src="NemanjaSimikic"
                class="img-fluid"
                alt="Nemanja Simikic"
              />
              <div>
                <h3>NEMANJA SIMIKIC</h3>
                <p>.NET Developer</p>
              </div>
            </a>
          </b-col>

          <b-col cols="6" md="3" lg="3" xl="2" class="p-0">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/company/ns-web-development-doo-novi-sad"
            >
              <img
                :src="AleksandraGrujic"
                class="img-fluid"
                alt="Aleksandra Grujic"
              />
              <div>
                <h3>ALEKSANDRA GRUJIC</h3>
                <p>.NET Developer</p>
              </div>
            </a>
          </b-col>

          <b-col cols="6" md="3" lg="3" xl="2" class="p-0">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/company/ns-web-development-doo-novi-sad"
            >
              <img :src="DraganDulic" class="img-fluid" alt="Dragan Dulic" />
              <div>
                <h3>DRAGAN DULIC</h3>
                <p>.NET Developer</p>
              </div>
            </a>
          </b-col>

          <b-col cols="6" md="3" lg="3" xl="2" class="p-0">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/full-stack-web-developer-and-engineer/"
            >
              <img :src="SaraPajcin" class="img-fluid" alt="Sara Ilic" />
              <div>
                <h3>SARA ILIC</h3>
                <p>.NET Developer</p>
              </div>
            </a>
          </b-col>

          <b-col cols="6" md="3" lg="3" xl="2" class="p-0">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/marko-juri%C4%87-860b5019b/"
            >
              <img :src="MarkoJuric" class="img-fluid" alt="Marko Juric" />
              <div>
                <h3>MARKO JURIC</h3>
                <p>.NET Developer</p>
              </div>
            </a>
          </b-col>

          <b-col cols="6" md="3" lg="3" xl="2" class="p-0">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/visnja-sretenovic-051b951b1/"
            >
              <img
                :src="VisnjaSretenovic"
                class="img-fluid"
                alt="Visnja Sretenovic"
              />
              <div>
                <h3>VISNJA SRETENOVIC</h3>
                <p>.NET Developer</p>
              </div>
            </a>
          </b-col>

           <b-col cols="6" md="3" lg="3" xl="2" class="p-0">
           <img
             :src="Quote5"
             class="img-fluid"
             alt="Happiness is working with great people"
           />
         </b-col>

          <b-col cols="6" md="3" lg="3" xl="2" class="p-0">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/company/ns-web-development-doo-novi-sad"
            >
              <img :src="SrdjanKokot" class="img-fluid" alt="Srdjan Kokot" />
              <div>
                <h3>SRDJAN KOKOT</h3>
                <p>Android Developer</p>
              </div>
            </a>
          </b-col>

          <b-col cols="6" md="3" lg="3" xl="2" class="p-0">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/milos-gojic-62781b40/"
            >
              <img
                :src="MilosGojic"
                class="img-fluid"
                alt="Milos Gojic"
              />
              <div>
                <h3>Milos Gojic</h3>
                <p>Flutter Developer</p>
              </div>
            </a>
          </b-col>

          <b-col cols="6" md="3" lg="3" xl="2" class="p-0">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/kristian-firedi-441574178/"
            >
              <img
                :src="KristianFiredi"
                class="img-fluid"
                alt="Kristian Firedi"
              />
              <div>
                <h3>KRISTIAN FIREDI</h3>
                <p>Flutter Developer</p>
              </div>
            </a>
          </b-col>

          <b-col cols="6" md="3" lg="3" xl="2" class="p-0">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/radovan-klje%C5%A1tan-17b685202/"
            >
              <img
                :src="RadovanKljestanWebp"
                class="img-fluid"
                alt="Radovan Kljesto"
              />
              <div>
                <h3>RADOVAN KLJESTAN</h3>
                <p>Flutter Developer</p>
              </div>
            </a>
          </b-col>

          <b-col
            cols="6"
            md="3"
            lg="3"
            xl="2"
            class="p-0 d-none d-sm-block d-md-none d-lg-none d-xl-none"
          >
            <img
              :src="Quote5"
              class="img-fluid"
              alt="Good colleagues are those"
            />
          </b-col>

          <b-col
            cols="6"
            md="3"
            lg="3"
            xl="2"
            class="p-0 d-none d-sm-none d-md-block d-lg-block d-xl-none"
          >
            <img
              :src="Quote2"
              class="img-fluid"
              alt="Good colleagues are those"
            />
          </b-col>

          <b-col cols="6" md="3" lg="3" xl="2" class="p-0">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/milan-brati%C4%87-134a9b1b0/"
            >
              <img :src="MilanBratic" class="img-fluid" alt="Milan Bratic" />
              <div>
                <h3>MILAN BRATIC</h3>
                <p>Unity Developer</p>
              </div>
            </a>
          </b-col>
          
          <b-col
            cols="6"
            md="3"
            lg="3"
            xl="2"
            class="p-0 d-none d-sm-block d-md-none d-lg-none d-xl-none"
          >
            <img
              :src="Quote5"
              class="img-fluid"
              alt="Good colleagues are those"
            />
          </b-col>

           <b-col cols="6" md="3" lg="3" xl="2" class="p-0">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/neboj%C5%A1a-slijep%C4%8Devi%C4%87-59a1a81a3/"
            >
              <img :src="Prazna" class="img-fluid" alt="Nebojsa Slijepcevic" />
              <div>
                <h3>NEBOJSA SLIJEPCEVIC</h3>
                <p>Unity Developer</p>
              </div>
            </a>
          </b-col>

           <b-col cols="6" md="3" lg="3" xl="2" class="p-0">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/milo%C5%A1-stankovi%C4%87-833505263/"
            >
              <img :src="Prazna" class="img-fluid" alt="Milos Stankovic" />
              <div>
                <h3>MILOS STANKOVIC</h3>
                <p>Unity Developer</p>
              </div>
            </a>
          </b-col>

          <b-col cols="6" md="3" lg="3" xl="2" class="p-0">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/kre%C5%A1imir-kri%C5%A1to-b01355113/"
            >
              <img :src="Prazna" class="img-fluid" alt="Kresimir Kristo" />
              <div>
                <h3>KRESIMIR KRISTO</h3>
                <p>Project Writer</p>
              </div>
            </a>
          </b-col>

          <b-col cols="6" md="3" lg="3" xl="2" class="p-0">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/vladimir-dolap%C4%8Dev-39436a138/"
            >
              <img
                :src="VladimirDolapcev"
                class="img-fluid"
                alt="Vladimir Dolapcev"
              />
              <div>
                <h3>VLADIMIR DOLAPCEV</h3>
                <p>Web Content Editor</p>
              </div>
            </a>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      SrdjanVukmirovic: require("@/assets/img/team/Srdjan-Vukmirovic.webp"),
      AnaVukmirovic: require("@/assets/img/team/Ana-Vukmirovic.webp"),
      AndrijaSagi: require("@/assets/img/team/Andrija-Sagi.webp"),
      BlagojeBulajic: require("@/assets/img/team/Blagoje-Bulajic.webp"),
      BorisAndrejic: require("@/assets/img/team/Boris-Andrejic.webp"),
      DejanVukmirovic: require("@/assets/img/team/Dejan-Vukmirovic.webp"),
      JovicaConkic: require("@/assets/img/team/Jovica-Conkic.webp"),
      MarkoJuric: require("@/assets/img/team/Marko-Juric.webp"),
      MartinaMarkovic: require("@/assets/img/team/Martina-Markovic.webp"),
      NemanjaSimikic: require("@/assets/img/team/Nemanja-Simikic.webp"),
      MiljkoMiljkovic: require("@/assets/img/team/Miljko-Miljkovic.webp"),
      AleksandraGrujic: require("@/assets/img/team/Aleksandra-Grujic.webp"),
      DraganDulic: require("@/assets/img/team/Dragan-Dulic.webp"),
      SaraPajcin: require("@/assets/img/team/Sara-Pajcin.webp"),
      VisnjaSretenovic: require("@/assets/img/team/Visnja-Sretenovic.webp"),
      ZoranDamjanov: require("@/assets/img/team/Zoran-Damjanov.webp"),
      SrdjanKokot: require("@/assets/img/team/Srdjan-Kokot.webp"),
      VladimirDolapcev: require("@/assets/img/team/Vladimir Dolapcev.webp"),
      MilosMarkovic: require("@/assets/img/team/Milos-Markovic.webp"),
      KristianFiredi: require("@/assets/img/team/Kristian-Firedi.webp"),
      MilanBratic: require("@/assets/img/team/Milan-Bratic.webp"),
      RadovanKljestanWebp: require("@/assets/img/team/Radovan Kljestan.webp"),
      RadovanKljestanJpg: require("@/assets/img/team/Radovan-Kljestan.jpg"),
      MilosGojic: require("@/assets/img/team/Milos-Gojic.jpg"),
      NikolaSavic: require("@/assets/img/team/Nikola-Savic.png"),
      KatarinaMajor: require("@/assets/img/team/Katarina-Major.png"),
      Prazna: require("@/assets/img/team/default.webp"),
      Quote1: require("@/assets/img/team/quote1.webp"),
      Quote2: require("@/assets/img/team/quote2.webp"),
      Quote3: require("@/assets/img/team/quote3.webp"),
      Quote4: require("@/assets/img/team/quote4.webp"),
      Quote5: require("@/assets/img/team/quote5.webp"),
    };
  },
};
</script>
