<template>
  <div id="PageCartoonsDetails">
    <b-container class="description">
      <h5><span>CARTOON</span></h5>
      <h2>TOM & TOMMY</h2>
      <p>
        Tom and Tommy are twins. They are hard to distinguish, but their eye
        color gives them away. Their room is their little world filled with
        toys, where the kids are kings, and the games are endless. Tom and Tommy
        change roles each day and are always plotting against each other! The
        boys are full of life and energy as they constantly try to find a way to
        outsmart one another and place new traps in their room.
      </p>
      <p>
        They don't choose the time of day to get into trouble since it's always
        chaos around them, even during the night. Tom is the blue-eyed menace,
        always ready to find creative ways to bug his brother. Tommy just looks
        like a cute green-eyed boy, but he loves to outsmart and ruin his
        sibling's fun for his own pleasure. Enjoy the adventures of Tom and
        Tommy and have a great laugh while cheering for your favorite twin.
      </p>
    </b-container>

    <b-container class="stores">
      <b-container>
        <div class="store">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.youtube.com/watch?v=AoeB8akYIlk&ab_channel=NSWebDevelopment"
            class="btn"
            >TRAILER</a
          >
        </div>
        <div class="store">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://wolfmedia.media/"
            class="btn"
            >WEBSITE</a
          >
        </div>
      </b-container>
    </b-container>

    <b-container fluid class="details">
      <div class="container_1920">
        <b-row>
          <b-col cols="12" sm="12" md="12" lg="6" xl="6">
            <b-img
              fluid
              class="img_w"
              :src="goldenfive"
              alt="Tom and Tommy"
            ></b-img>
          </b-col>
          <b-col cols="12" sm="12" md="12" lg="6" xl="6" class="detail">
            <b-container>
              <h5><span>PRODUCTION</span></h5>
              <p>NSWD</p>
            </b-container>
            <b-container>
              <h5><span>GENRE</span></h5>
              <p>Cartoon TV serial</p>
            </b-container>
            <b-container>
              <h5><span>DURATION</span></h5>
              <p>52 ep x 2.5min</p>
            </b-container>
            <b-container>
              <h5><span>YEAR</span></h5>
              <p>2017</p>
            </b-container>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      goldenfive: require("@/assets/img/Cartoons/Tom-&-Tommy.webp"),
    };
  },
};
</script>