<template>
  <div id="WebsiteDetails">
    <b-container class="description">
      <h5><span>WEBSITE</span></h5>
      <h2>DISINFECTION DOOR</h2>
      <p>
        In this new situation and the danger posed by the rapid spread of the
        COVID-19 virus, we cannot rely on standard hygiene practices and habits.
        For the safety of all employees and those who visit your facilities, it
        is necessary to implement preventive measures and strictly take care
        that everyone is appropriately disinfected before entering. Disinfection
        doors are used to decontaminate people, animals, goods, and other
        subjects at the entrances to public facilities, hospitals, factories,
        hotels, shopping malls, and many other places.
      </p>
      <p>
        The disinfection door's purpose goes beyond the protection from the
        COVID-19 virus, as it's also reduced the spreading of other diseases in
        general. By disinfecting the whole body, we secure that the person is
        not carrying any bacteria or other parasites on its clothes, hair,
        hands, or footwear. Explore the website for more details.
      </p>
    </b-container>

    <b-container class="stores">
      <b-container>
        <div class="store">
          <a
            
            rel="noreferrer"
            href=""
            class="btn"
            >WEBSITE</a
          >
        </div>
      </b-container>
    </b-container>

    <b-container fluid class="details">
      <div class="container_1920">
        <b-row>
          <b-col cols="12" sm="12" md="12" xl="6" lg="6">
            <b-img
              fluid
              class="img_w"
              :src="DisinfectionDoor"
              alt="Disinfection door"
            ></b-img>
          </b-col>
          <b-col cols="12" sm="12" md="12" xl="6" lg="6" class="detail">
            <b-container>
              <h5><span>CLIENT</span></h5>
              <p>LMJ LLC</p>
            </b-container>
            <b-container>
              <h5><span>SERVICE</span></h5>
              <p>Design, Web Development, SEO</p>
            </b-container>
            <b-container>
              <h5><span>TECHNOLOGIES</span></h5>
              <p>Laravel, HTML & CSS</p>
            </b-container>
            <b-container>
              <h5><span>YEAR</span></h5>
              <p>2020</p>
            </b-container>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      DisinfectionDoor: require("@/assets/img/web/dezinfekciona-vrata.webp"),
    };
  },
};
</script>
