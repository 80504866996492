<template>
  <div id="GameDetails">
    <b-container class="description">
      <h5><span>GAME</span></h5>
      <h2>PALADINS OF THE GALAXY</h2>
      <p>
        Be the protector of your galaxy, grab your light sword once again and
        prove yourself for the one last time in this galactic battle! Choose
        your side wisely and seal your destiny! Step into a fantastic sci-fi
        world to pick your favorite character and face the evil forces to save
        your home! Battle on different planets, enjoy beautiful graphics, and
        become the ultimate Paladin of the Galaxy in this action game.
      </p>
    </b-container>

    <b-container class="stores">
      <b-container>
        <div class="store">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.youtube.com/watch?v=myy8jCvkpbQ"
            class="btn"
            >TRAILER</a
          >
        </div>
        <div class="store">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://paladins.nswebdevelopment.com/"
            class="btn"
            >WEBSITE</a
          >
        </div>
      </b-container>
      <b-container>
        <div class="store_img">
          <div class="store">
            <a
              
              rel="noreferrer"
              href=""
            >
              <b-img
                :src="appstore"
                class="img-fluid"
                alt="Appstory logo"
              ></b-img>
            </a>
          </div>
        </div>
      </b-container>
    </b-container>

    <b-container fluid class="details">
      <div class="container_1920">
        <b-row>
          <b-col
            cols="12"
            sm="12"
            md="12"
            lg="6"
            xl="6"
            p-sm="0"
            class="bg_paladins"
          >
            <b-img
              fluid
              class="img_h"
              :src="paladin"
              alt="Paladin of the galaxy"
            ></b-img>
          </b-col>
          <b-col cols="12" sm="12" md="12" lg="6" xl="6" class="detail">
            <b-container>
              <h5><span>CLIENT</span></h5>
              <p>NSWD</p>
            </b-container>
            <b-container>
              <h5><span>GENRE</span></h5>
              <p>Mobile FPS game</p>
            </b-container>
            <b-container>
              <h5><span>SERVICES</span></h5>
              <p>Android, iOS and WebGL Development</p>
            </b-container>
            <b-container>
              <h5>TECHNOLOGIES</h5>
              <p>C#, Unity 2D/3D</p>
            </b-container>
            <b-container>
              <h5><span>YEAR</span></h5>
              <p>2017</p>
            </b-container>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      paladin: require("@/assets/img/games/paladins-of-the-galaxy.webp"),
      appstore: require("@/assets/img/AppStore.webp"),
      playstore: require("@/assets/img/GooglePlay.webp"),
    };
  },
};
</script>
