<template>
  <div id="PageApplication">
    <b-container class="description">
      <h5><span>APPLICATIONS</span></h5>
      <h2>IOS & ANDROID & WEB</h2>
      <p>
        We build amazing fully-customized apps for iOS, Android, and the web.
        From idea conception to product launch and maintenance, we cover the
        entire development process.
      </p>
    </b-container>

    <b-container fluid class="p-0">
      <b-container class="products">
        <b-row>
          <b-col cols="6" sm="6" md="6" lg="6" xl="6">
            <div class="img-box">
              <router-link to="/applications/archon-compass">
                <b-img fluid :src="phone7" alt="Archon"></b-img>
                <div class="title_div">
                  <h2>ARCHON COMPASS</h2>
                </div>
              </router-link>
            </div>
          </b-col>

          <b-col cols="6" sm="6" md="6" lg="6" xl="6">
            <div class="img-box">
              <router-link to="/applications/esar">
                <b-img fluid :src="phone4" alt="Esar"></b-img>
                <div class="title_div">
                  <h2>ESAR</h2>
                </div>
              </router-link>
            </div>
          </b-col>

          <b-col cols="6" sm="6" md="6" lg="6" xl="6">
            <div class="img-box">
              <router-link to="/applications/the-success-plan">
                <b-img
                  fluid
                  :src="thesuccessplan"
                  alt="The Success plan"
                ></b-img>
                <div class="title_div">
                  <h2>THE SUCCESS PLAN</h2>
                </div>
              </router-link>
            </div>
          </b-col>

          <b-col cols="6" sm="6" md="6" lg="6" xl="6">
            <div class="img-box">
              <router-link to="/applications/machine-can-see">
                <b-img fluid :src="machincansee" alt="Machine Can See"></b-img>
                <div class="star">
                  <b-img alt="Time tracker" fluid :src="star"></b-img>
                </div>
                <div class="title_div">
                  <h2>MACHINE CAN SEE</h2>
                </div>
              </router-link>
            </div>
          </b-col>

          <b-col cols="6" sm="6" md="6" lg="6" xl="6">
            <div class="img-box">
              <router-link to="/applications/time-tracking">
                <b-img fluid :src="phone3" alt="Time tracking"></b-img>
                <div class="star">
                  <b-img alt="Time tracker" fluid :src="star"></b-img>
                </div>
                <div class="title_div">
                  <h2>TIME TRACKING</h2>
                </div>
              </router-link>
            </div>
          </b-col>

          <b-col cols="6" sm="6" md="6" lg="6" xl="6">
            <div class="img-box">
              <router-link to="/applications/my-spot">
                <b-img fluid :src="phone1" alt="My spot"></b-img>
                <div class="title_div">
                  <h2>MY SPOT</h2>
                </div>
              </router-link>
            </div>
          </b-col>

          <b-col cols="6" sm="6" md="6" lg="6" xl="6">
            <div class="img-box">
              <router-link to="/applications/find-loyalty">
                <b-img fluid :src="phone2" alt="Find Loyalty"></b-img>
                <div class="title_div">
                  <h2>FIND LOYALTY</h2>
                </div>
              </router-link>
            </div>
          </b-col>

          <b-col cols="6" sm="6" md="6" lg="6" xl="6">
            <div class="img-box">
              <router-link to="/applications/tatoshi-wallet">
                <b-img fluid :src="phone5" alt="Tatoshi Wallet"></b-img>
                <div class="title_div">
                  <h2>TATOSHI WALLET</h2>
                </div>
              </router-link>
            </div>
          </b-col>

          <b-col cols="6" sm="6" md="6" lg="6" xl="6">
            <div class="img-box">
              <router-link to="/applications/food-ordering">
                <b-img fluid :src="phone8" alt="Food Ordering"></b-img>

                <div class="title_div">
                  <h2>FOOD ORDERING</h2>
                </div>
              </router-link>
            </div>
          </b-col>

          <b-col cols="6" sm="6" md="6" lg="6" xl="6">
            <div class="img-box">
              <router-link to="/applications/rimo">
                <b-img fluid :src="phone9" alt="Rimo"></b-img>
                <div class="title_div">
                  <h2>RIMO</h2>
                </div>
              </router-link>
            </div>
          </b-col>
          <b-col cols="6" sm="6" md="6" lg="6" xl="6">
            <div class="img-box">
              <router-link to="/applications/clinic-in-cloud">
                <b-img fluid :src="phone10" alt="Clinic in Cloud"></b-img>
                <div class="star">
                  <b-img alt="star" fluid :src="star"></b-img>
                </div>
                <div class="title_div">
                  <h2>CLINIC IN CLOUD</h2>
                </div>
              </router-link>
            </div>
          </b-col>
          <b-col cols="6" sm="6" md="6" lg="6" xl="6">
            <div class="img-box">
              <router-link to="/applications/ni-analytics">
                <b-img fluid :src="phone12" alt="Ni Analytics"></b-img>
                <div class="title_div">
                  <h2>NI ANALYTICS</h2>
                </div>
              </router-link>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phone1: require("@/assets/img/aplication/my-spot.webp"),
      phone2: require("@/assets/img/aplication/find-loyalty.webp"),
      phone3: require("@/assets/img/aplication/time-tracking.webp"),
      phone4: require("@/assets/img/aplication/esar.webp"),
      phone5: require("@/assets/img/aplication/tatoshi-wallet.webp"),
      phone6: require("@/assets/img/aplication/dunavski-park.webp"),
      phone7: require("@/assets/img/aplication/Archon-Compass.webp"),
      phone8: require("@/assets/img/aplication/food-ordering.webp"),
      phone9: require("@/assets/img/aplication/rimo.webp"),
      phone10: require("@/assets/img/aplication/clinic-in-cloud.webp"),
      phone11: require("@/assets/img/aplication/spms.jpg"),
      phone12: require("@/assets/img/aplication/ni-analytics.webp"),

      thesuccessplan: require("@/assets/img/aplication/The-Success-Plan.webp"),
      machincansee: require("@/assets/img/aplication/machine-can-see.webp"),

      star: require("@/assets/img/star-solid.webp"),
    };
  },
};
</script>
