<template>
  <b-container fluid class="ContactForm">
    <h2>GET IN TOUCH</h2>
    <b-form @submit.prevent="send">
      <b-form-group>
        <b-form-input
          name="fname"
          type="text"
          v-model="form.fname"
          v-validate="'required|alpha_spaces'"
          :class="{ 'is-invalid': errors.first('fname') }"
          placeholder="Full name*"
        ></b-form-input>
        <b-form-invalid-feedback
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group>
        <b-form-input
          name="email"
          type="email"
          v-model="form.email"
          v-validate="'required|email'"
          :class="{ 'is-invalid': errors.first('email') }"
          placeholder="Email*"
        ></b-form-input>
        <b-form-invalid-feedback
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>
      
      <b-form-group>
        <b-form-input
          name="subject"
          type="text"
          v-model="form.subject"
          v-validate="'required'"
          :class="{ 'is-invalid': errors.first('subject') }"
          placeholder="Subject"
        ></b-form-input>
      </b-form-group>

      <b-form-group>
        <b-form-textarea
          name="message"
          v-model="form.message"
          v-validate="'required'"
          :class="{ 'is-invalid': errors.first('message') }"
          placeholder="Message"
          rows="5"
          max-rows="5"
        ></b-form-textarea>

      </b-form-group>
      
      <b-container v-show="shower" 
      class="alert" 
      :class="shower ? 'alert-success' : 'alert-danger'"
      role="alert">
        <p>{{ status }}</p>
      </b-container>
      <b-form-group>
        <b-button type="submit" variant="primary" class="btn-contact"
          >SEND</b-button
        >
      </b-form-group>
    </b-form>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      form: {
        fname: "",
        email: "",
        subject: "",
        message: "",
      },
      status: "",
      shower: false,
      cololor: null,
    };
  },
  methods: {
    send() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$recaptcha("login").then((token) => {
            if(token) {
            this.axios
              .post(__dirname + "email.php", {
                fname: this.form.fname,
                email: this.form.email,
                subject: this.form.subject,
                message: this.form.message,
                site: "nswd",
              })
              .then((response) => {
                this.shower = true;
                if(response.data == "success") {
                  this.status = "Send success!"
                  this.cololor = true;
                } else {
                  this.status = "Send success!"
                  this.cololor = false;
                }
                this.reset();
                this.clierForm();
                this.$nextTick(() => {
                  this.$validator.reset();
                });
              })
              .catch((error) => {
                console.log(error);
              });
            }
          });
        } else {
          this.shower = false;
        }
      });
    },
    reset () {
      window.grecaptcha.reset(this.widgetId)
    },
    clierForm() {
      this.form.fname = null;
      this.form.email = null;
      this.form.subject = null;
      this.form.message = null;
    },
  },
};
</script>