<template>
  <div id="PageCareerD">
    <b-container class="pt-100">
      <h5><span>CAREER</span></h5>
      <h2>UNITY OR UNITY 3D DEVELOPER</h2>
      <p>
        As a team member, you'll work on creating Unity-based framework games
        for various platforms. Your primary competence will be to build
        appealing games by implementing concepts, design ideas, and
        requirements. A dedication to creating a quality product with
        sophisticated design and solving problems with other team members is of
        the essence.
      </p>
    </b-container>

    <b-container class="pt-120">
      <h2>POSITION REQUIREMENTS</h2>
      <ul>
        <li>Convert design framework into a working game</li>
        <li>Create in-game functionality based on communicated design input</li>
        <li>
          Integrate media assets and initiate an effective pipeline in
          communication with other members of the team
        </li>
        <li>
          Design, create and maintain reliable code that is reusable and
          efficient
        </li>
        <li>
          Pinpoint bugs and restrictions, and find the most efficient solutions
          for these problems
        </li>
        <li>
          Optimize developed applications to secure the top performance and
          responsiveness while not sacrificing the quality
        </li>
        <li>
          Participate in maintaining and improving organization, automatization,
          and a code quality
        </li>
      </ul>
    </b-container>

    <b-container class="pt-120">
      <h2>SKILLS</h2>
      <ul>
        <li>
          Exceptional knowledge of working in Unity, including familiarity with
          textures, GUI styles, textures, scripting, and user sessions
          administration
        </li>
        <li>Experience developing games for console and mobile platforms</li>
        <li>Experience in planning and level designing</li>
        <li>Knowledge of particle systems and game physics</li>
        <li>
          The ability to support older hardware by optimizing system
          requirements, including RAM and space usage
        </li>
        <li>Familiarity with 3D is considered a plus</li>
        <li>
          Previous development experience on successful titles is a significant
          advantage
        </li>
        <li>
          Motivation to constantly learn about new development tools &
          technologies, and game standards advancements
        </li>
        <li>Deep knowledge of object-oriented programming</li>
        <li>Awareness of the latest architectural patterns and design</li>
        <li>
          Skillful in writing code that is readable, clean, and easily
          maintained
        </li>
        <li>
          Experience in implementing automated unit test and testing platforms
        </li>
      </ul>
    </b-container>

    <AppCareersContact position="Unity Developer"></AppCareersContact>
  </div>
</template>

<script>
import AppCareersContact from "./AppCareersContact";

export default {
  components: {
    AppCareersContact,
  },
};
</script>
