<template>
  <div id="WebsiteDetails">
    <b-container class="description">
      <h5><span>WEBSITE</span></h5>
      <h2>COFFEE BEANS</h2>
      <p>
        Who wouldn't like to know their future? Plus, if you are doing it, it
        should be fun too! Tasseography, better known as Coffee Cup Reading, is
        a fortune-telling method. As coffee grounds and tea leaves create a
        pattern in the cup, they can be read to help you better understand
        yourself.
      </p>
      <p>
        The Coffee Beans app makes this process streamlined and saves your time.
        All you need to do is take a picture of your cup's inner side and submit
        it together with your name, relationship status, and birth date. Once
        you provide all the details, local fortune tellers will review it and
        send the results to your smart device. Check the website to learn more
        and take control of your destiny!
      </p>
    </b-container>

    <b-container class="stores">
      <b-container>
        <div class="store">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://coffeebeans.nswebdevelopment.com/"
            class="btn"
            >WEBSITE</a
          >
        </div>
      </b-container>
    </b-container>

    <b-container fluid class="details">
      <div class="container_1920">
        <b-row>
          <b-col cols="12" sm="12" md="12" xl="6" lg="6">
            <b-img fluid class="img_w" :src="phone1" alt="Coffee beans"></b-img>
          </b-col>
          <b-col cols="12" sm="12" md="12" xl="6" lg="6" class="detail">
            <b-container>
              <h5><span>CLIENT</span></h5>
              <p>NSWD</p>
            </b-container>
            <b-container>
              <h5><span>SERVICE</span></h5>
              <p>Software Development, Mobile Development, SEO</p>
            </b-container>
            <b-container>
              <h5><span>TECHNOLOGIES</span></h5>
              <P>C#, HTML & CSS, XML, Java, Android</P>
            </b-container>
            <b-container>
              <h5><span>YEAR</span></h5>
              <p>2016</p>
            </b-container>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phone1: require("@/assets/img/web/coffe-beans.webp"),
    };
  },
};
</script>
