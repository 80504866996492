<template>
  <div id="PageCareerD">
    <b-container class="pt-100">
      <h5><span>CAREER</span></h5>
      <h2>PHP DEVELOPER</h2>
      <p>
        PHP Developer is responsible for exchanging data between the users and
        the servers and managing back-end services. Your primary authority
        includes defining and maintaining the main database, securing
        responsiveness, and top performance. Also, you will develop complete
        server-side logic and implement front-end elements created by other team
        members. For that reason, it's necessary to have a basic knowledge of
        front-end development.
      </p>
    </b-container>

    <b-container class="pt-120">
      <h2>RESPONSIBILITIES</h2>
      <ul>
        <li>Create structured, reusable, and testable PHP modules</li>
        <li>
          Integrate user interface solutions built by front-end developers
        </li>
        <li>
          Incorporate various data solutions, including blob stores, databases,
          or key-value stores
        </li>
        <li>
          Be ready to find solutions for architectural and performance issues
        </li>
      </ul>
    </b-container>

    <b-container class="pt-120">
      <h2>SKILLS</h2>
      <ul>
        <li>
          In-depth knowledge of various PHP web frameworks, such as Lavarel
        </li>
        <li>Knowledge of the fully synchronous PHP behavior</li>
        <li>Comprehension of MVC design schemes</li>
        <li>Essential familiarity with front-end development, including CSS3, HTML5 and JavaScript technologies</li>
        <li>Familiarity with object-oriented PHP development</li>
        <li>Security and security compliance comprehension (unique for every project)</li>
        <li>Expertise in widespread server and PHP exploits and familiarity with solutions for these problems</li>
        <li>Authorization and authentication of users between several servers, environments, and systems</li>
        <li>Proficiency in essential design principles at the back of the scalable application</li>
        <li>Understanding of PHP platform limitations and possible workarounds</li>
        <li>Multiple databases and data source integration into a single system</li>
        <li>Understanding of SQL/NoSQL databases, including their declarative query languages</li>
        <li>Expert code versioning tools knowledge, including Git</li>
        <li>Developing database schemas for supporting and representing business operations</li>
      </ul>
    </b-container>

    <AppCareersContact position="PHP Developer"></AppCareersContact>
  </div>
</template>

<script>
import AppCareersContact from "./AppCareersContact";

export default {
  components: {
    AppCareersContact
  }
};
</script>
