<template>
  <div id="ApplicationD">
    <b-container class="description">
      <h5><span>APPLICATION</span></h5>
      <h2>TATOSHI WALLET</h2>
      <p>
       Tatoshi Wallet is a decentralized self-storage solution preventing the loss of your private key and the related Bitcoins. 
       With our app, you can store recovery data within your friends' and family's phones. If you lose your phone and private key, 
       you can quickly regain it with your friends' help. Involving your friends to protect you from loss is called social recovery.
      </p>
    </b-container>

    <b-container class="stores">
      <b-container>
        <div class="store">
          <a target="_blank" rel="noreferrer" href="https://tatoshi.io/" class="btn">WEBSITE</a>
        </div>
      </b-container>
      <b-container>
        <div class="store_img">
        <div class="store">
          <a target="_blank" rel="noreferrer" href="https://apps.apple.com/ch/app/tatoshi-recovery-wallet/id1444394663?fbclid=IwAR1klocV1mI6A4tF3RhjxFJPLGOk1eN7LG7TnjdNezQhPJy6mJa7pLdY7DE" class="store">
            <b-img :src="appstory" alt="Appstory logo"></b-img>
          </a>
        </div>
        </div>
      </b-container>
    </b-container>

    <b-container fluid class="details">
      <div class="container_1920">
      <b-row>
        <b-col cols="12" sm="12" md="12" xl="6" lg="6">
          <b-img fluid class="img_w" :src="phone1" alt="Tatoshi Wallet"></b-img>
        </b-col>
        <b-col cols="12" sm="12" md="12" xl="6" lg="6" class="detail">
          <div class="box">
          <b-container>
            <h5><span>CLIENT</span></h5>
            <p>Tatoshi AG, Switzerland</p>
          </b-container>
          <b-container>
            <h5><span>SERVICE</span></h5>
            <p>Software Development, Mobile Development, SEO</p>
          </b-container>
          <b-container>
            <h5><span>TECHNOLOGIES</span></h5>
            <p>C#, HTML & CSS, XML, Java, Android</p>
          </b-container>
          <b-container>
            <h5><span>YEAR</span></h5>
            <p>2017</p>
          </b-container>
          </div>
        </b-col>
      </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phone1: require("@/assets/img/aplication/tatoshi-wallet.webp"),
      appstory: require("@/assets/img/AppStore.webp"),
      playstory: require("@/assets/img/GooglePlay.webp")
    };
  }
};
</script>
