<template>
  <div id="GameDetails">
    <b-container class="description">
      <h5><span>GAME</span></h5>
      <h2>MANY REAL GUNS</h2>
      <p>
        Even though there is an abundance of mods for a well-known game with
        blocky graphics, not many are translated into a mini-game for mobile
        devices. Many Real Guns Mod game is a pocket edition of a blocky
        graphics 3D first-person shooter based on survival and war mods and
        addons like desnoguns, block, epic, heuristick's,stefinus, ferullo's,
        techguns, wold and other gun mods. Choose your character and map, pick
        your favorite weapon, upgrade it and start fighting.
      </p>
      <p>
        You can choose from a vast range of real-life guns, such as AK47,
        Machine Gun, M4, Thompson, Uzi, Sniper, and many more! Can you get the
        best score in a single match, or are you good enough to be on the
        all-time best leader-board? You can select three gameplay types,
        including the single-player Training, where enemies constantly attack
        you. Try to survive for as long as you can, get the achievement, and
        climb the rankings. In multiplayer, you can choose between two modes,
        including classic Deathmatch and Team match, where you can form your
        clan and prove to your enemies that you are no joke. Collect as much
        gold as you can and upgrade your weapons to be even stronger against
        other players.
      </p>
    </b-container>

    <div class="suportby">
      <h2>CUSTOMER REVIEWS</h2>
      <div class="logo-img">
        <b-img :src="amazonbest" alt="Amazon best logo"></b-img>
      </div>
    </div>

    <b-container class="stores">
      <b-container>
        <div class="store_img">
          <div class="store">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://us.amazon.com/Pixel-Hunt-Many-Real-Guns/dp/B071P8C6W2/ref=sr_1_2?dchild=1&keywords=dv+games&qid=1608885121"
            >
              <b-img :src="amazon" alt="Amazon logo" class="img-fluid"></b-img>
            </a>
          </div>
        </div>
      </b-container>
    </b-container>

    <b-container fluid class="details">
      <div class="container_1920">
        <b-row>
          <b-col cols="12" sm="12" md="12" lg="6" xl="6" p-sm="0">
            <div class="star">
          <b-img alt="Time tracker" fluid :src="star"></b-img>
        </div>
            <b-img
              class="img-full"
              :src="manyrealgane"
              alt="Many Real Guns"
            ></b-img>
          </b-col>
          <b-col cols="12" sm="12" md="12" lg="6" xl="6" class="detail">
            <b-container>
              <h5><span>CLIENT</span></h5>
              <p>DV Games</p>
            </b-container>
            <b-container>
              <h5><span>GENRE</span></h5>
              <p>Mobile FPS game</p>
            </b-container>
            <b-container>
              <h5><span>SERVICES</span></h5>
              <p>Android, iOS and WebGL Development</p>
            </b-container>
            <b-container>
              <h5><span>TECHNOLOGIES</span></h5>
              <p>C#, Unity 2D/3D</p>
            </b-container>
            <b-container>
              <h5><span>YEAR</span></h5>
              <p>2017</p>
            </b-container>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      manyrealgane: require("@/assets/img/games/many-real-guns.webp"),
      amazon: require("@/assets/img/amazon.webp"),
      amazonbest: require("@/assets/img/Logos/amazon-logo.webp"),
      star: require("@/assets/img/star-solid.webp"),
    };
  },
};
</script>
