<template>
  <div id="AppHeader">
    <b-container fluid class="phones">
      <div class="container_1920">
        <p class="phone">
          <span
            ><a :href="`tel:${telephon1}`"
              ><b-img :src="layer1" alt="Srbija"></b-img></a
            ></span
          >

          <span
            ><a :href="`tel:${telephon2}`"
              ><b-img :src="layer2" alt="Canada"></b-img> +16047247028</a
            ></span
          >

          <span
            ><a :href="`tel:${telephon3}`"
              ><b-img :src="layer3" alt="Netherlands"></b-img> +31654903507</a
            ></span
          >

          <span
            ><a :href="`tel:${telephon4}`"
              ><b-img :src="layer4" alt="Switzerland"></b-img> +41764355280</a
            ></span
          >
        </p>
      </div>
    </b-container>
    <b-container fluid id="navbar" :class="{sticky:active}">
      <div class="container_1920">
        <b-navbar toggleable="lg" type="dark">
          <b-navbar-brand to="/" class="m-0 p-0">
            <b-img
              class="logo"
              fluid
              :src="logo"
              alt="NS Web Development"
            ></b-img>
          </b-navbar-brand>
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="navbar-right">
              <b-nav-item-dropdown class="nav-dropdown" text="PRODUCTS">
                <b-dropdown-item to="/applications"
                  >APPLICATIONS</b-dropdown-item
                >
                <b-dropdown-item to="/games">GAMES</b-dropdown-item>
                <b-dropdown-item to="/cartoons">CARTOONS</b-dropdown-item>
                <b-dropdown-item to="/websites">WEBSITES</b-dropdown-item>
              </b-nav-item-dropdown>
              <b-nav-item to="/services">SERVICES</b-nav-item>
              <b-nav-item to="/team">TEAM</b-nav-item>
              <b-nav-item to="/careers">CAREERS</b-nav-item>
              <b-nav-dropdown class="nav-dropdown" text="CONTACT US">
                <b-dropdown-item to="/contact-rs">SERBIA</b-dropdown-item>
                <b-dropdown-item to="/contact-ca">CANADA</b-dropdown-item>
                <b-dropdown-item to="/contact-nl">NETHERLANDS</b-dropdown-item>
                <b-dropdown-item to="/contact-ch">SWITZERLAND</b-dropdown-item>
              </b-nav-dropdown>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      layer1: require("@/assets/img/layer1.svg"),
      layer2: require("@/assets/img/layer2.svg"),
      layer3: require("@/assets/img/layer3.svg"),
      layer4: require("@/assets/img/layer4.svg"),
      logo: require("@/assets/img/nswd.webp"),
      telephon1: "+381648455312",
      telephon2: "+16047247028",
      telephon3: "+31654903507",
      telephon4: "+41764355280",
      active: false
    };
  },
  methods: {
    toggleNavClass(){
        if(this.active == false){
          return 'nav'
        } else {
          return 'sticky-nav'
        }

    }
  },
  mounted(){
  window.document.onscroll = () => {
      let navBar = document.getElementById('navbar');
      if(window.scrollY > navBar.offsetTop){
        this.active = true;
        } else {
        this.active = false;
      }
    }
  }
};
</script>
<style>
 
</style>