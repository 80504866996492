<template>
  <div>
    <b-container fluid v-on:mousemove="paralax" id="main-img">
      <b-container fluid id="ser-icon">
        <carousel1 class="caro"></carousel1>
        <div class="container_1920">
          <div class="services">
            <div class="service_icon">
              <div class="ser_col">
                <router-link to="/services">
                  <b-img :src="WebApp" alt="Web applications"></b-img>
                  WEB APPLICATIONS
                </router-link>
              </div>
              <div class="ser_col">
                <router-link to="/services">
                  <b-img :src="Mobile" alt="Mobile solutions"></b-img>
                  MOBILE SOLUTIONS
                </router-link>
              </div>
              <div class="ser_col">
                <router-link to="/services">
                  <b-img
                    :src="Quality"
                    alt="Quality Assurance services"
                  ></b-img>
                  QUALITY ASSURANCE SERVICES
                </router-link>
              </div>
              <div class="ser_col">
                <router-link to="/services">
                  <b-img :src="Windows" alt="Windows applications"></b-img>
                  WINDOWS APPLICATIONS
                </router-link>
              </div>
              <div class="ser_col">
                <router-link to="/services">
                  <b-img :src="Gaming" alt="Gaming"></b-img>
                  GAMING
                </router-link>
              </div>
              <div class="ser_col">
                <router-link to="/services">
                  <b-img :src="Ux" alt="UX/UI"></b-img>
                  UX/UI
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </b-container>
    </b-container>

    <b-container fluid id="applications">
      <div class="container_1920">
        <b-row>
          <b-col
            cols="12"
            sm="12"
            md="12"
            lg="6"
            xl="6"
            order="12"
            order-sm="12"
          >
            <div class="box-r">
              <h5><span>APPLICATIONS</span></h5>
              <h2>IOS & ANDROID & WEB</h2>
              <p>
                We build amazing fully-customized apps for iOS, Android, and the
                web. From idea conception to product launch and maintenance, we
                cover the entire development process.
              </p>
              <router-link to="/applications" class="learn-more-r"
                >LEARN MORE</router-link
              >
            </div>
          </b-col>
          <b-col cols="12" sm="12" md="12" lg="6" xl="6" order="1" order-sm="1">
            <div class="box-img-l">
              <b-img
                class="img-fluid"
                :src="applications"
                fluid
                alt="Development of iOS and Android applications"
              ></b-img>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>

    <b-container fluid id="games">
      <div class="container_1920">
        <b-row>
          <b-col
            cols="12"
            sm="12"
            md="12"
            lg="6"
            xl="6"
            order="12"
            order-sm="12"
            order-md="12"
            class="col-element"
          >
            <div class="box">
              <h5><span>GAMES</span></h5>
              <h2>AMAZING WORLDS</h2>
              <p>
                Only people who like to play games can create amazing games! We
                develop competitive titles which provide thrilling gaming
                experiences for everyone.
              </p>
              <router-link to="/games" target="_self">LEARN MORE</router-link>
            </div>
          </b-col>
          <b-col
            cols="12"
            sm="12"
            md="12"
            lg="6"
            xl="6"
            order="1"
            order-sm="1"
            order-md="1"
            order-lg="12"
            order-xl="12"
            class="games-img"
          ></b-col>
        </b-row>
      </div>
    </b-container>

    <b-container fluid id="cartoons">
      <div class="container_1920">
        <b-row>
          <b-col cols="12" sm="12" md="12" lg="6" xl="6" class="cartoon-img">
            <video autoplay loop muted>
              <source :src="cartoon_video" type="video/mp4" />
            </video>
          </b-col>
          <b-col cols="12" sm="12" md="12" lg="6" xl="6" class="col-element">
            <div class="box">
              <h5><span>CARTOONS</span></h5>
              <h2>ANIMATED SERIES</h2>
              <p>
                Only people who keep their inner child alive in themselves can
                create great cartoons. That's why our skilled animators come
                together as a team and continuously make exciting entertainment
                products.
              </p>
              <router-link to="/cartoons" class="learn-more-l"
                >LEARN MORE</router-link
              >
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>

    <b-container fluid id="websites">
      <div class="container_1920">
        <b-row>
          <b-col
            cols="12"
            sm="12"
            md="12"
            lg="6"
            xl="6"
            order="12"
            order-sm="12"
            order-md="12"
            order-lg="1"
            order-xl="1"
            class="col-element"
          >
            <div class="box-l">
              <h5><span>WEBSITES</span></h5>
              <h2>CUSTOM WEBSITES</h2>
              <p>
                We use agile methodologies & the latest technology to develop
                superior applications and take your business to the next level.
              </p>
              <router-link to="/websites" class="learn-more-r"
                >LEARN MORE</router-link
              >
            </div>
          </b-col>

          <b-col
            cols="12"
            sm="12"
            md="12"
            lg="6"
            xl="6"
            order-sm="1"
            order-md="1"
            order-lg="12"
            order-xl="12"
          >
            <div class="box-img-r">
              <b-img
                :src="websites"
                fluid
                alt="Development of web sites"
                class="websites-img"
              ></b-img>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>

    <b-container fluid id="services">
      <div class="container_1920">
        <h5><span>SERVICES</span></h5>
        <h2>OUR MAIN SERVICES</h2>
        <b-container class="services p-0">
          <div class="serv_img">
            <b-img alt=".net logo" :src="net_color" class="img_color"></b-img>
          </div>
          <div class="serv_img">
            <b-img alt="php logo" :src="php_color" class="img_color"></b-img>
          </div>
          <div class="serv_img">
            <b-img
              alt="android logo"
              :src="android_color"
              class="img_color"
            ></b-img>
          </div>
          <div class="serv_img">
            <b-img alt="ios logo" :src="ios_color" class="img_color"></b-img>
          </div>
          <div class="serv_img">
            <b-img
              alt="unity logo"
              :src="unity_color"
              class="img_color"
            ></b-img>
          </div>
          <div class="serv_img">
            <b-img
              alt="angular logo"
              :src="angular_color"
              class="img_color"
            ></b-img>
          </div>
          <div class="serv_img">
            <b-img alt="vue logo" :src="vue_color" class="img_color"></b-img>
          </div>
          <div class="serv_img">
            <b-img
              alt="react logo"
              :src="react_color"
              class="img_color"
            ></b-img>
          </div>
          <div class="serv_img">
            <b-img
              alt="flutter logo"
              :src="flutter_color"
              class="img_color"
            ></b-img>
          </div>
          <div class="serv_img">
            <b-img
              alt="laravel logo"
              :src="laravel_color"
              class="img_color"
            ></b-img>
          </div>
        </b-container>
        <router-link to="/services">LEARN MORE</router-link>
      </div>
    </b-container>

    <b-container fluid id="our-team" v-prlx.background="{ speed: 0.1 }">
      <div class="container_1920">
        <div class="box-t">
          <h5><span>OUR TEAM</span></h5>
          <h2>GET TO KNOW US</h2>
          <p>
            We bring our experiences, background, and perspectives together to
            collaborate and achieve great things. Our team is empowered to
            innovate and let our skills take us in unexpected and exciting
            directions. Most importantly, we have the freedom to express
            ourselves and understand the processes we are facing.
          </p>
          <router-link to="/team">LEARN MORE</router-link>
        </div>
      </div>
    </b-container>

    <b-container fluid id="careers">
      <div class="container_1920">
        <b-row>
          <b-col cols="12" sm="12" md="12" lg="6" xl="6">
            <div class="box-img-l">
              <b-img
                :src="careers"
                fluid
                alt="Careers img"
                class="websites-img"
              ></b-img>
            </div>
          </b-col>
          <b-col cols="12" sm="12" md="12" lg="6" xl="6" class="col-element">
            <div class="box-r">
              <h5><span>CAREERS</span></h5>
              <h2>JOIN US</h2>
              <p>
                Want to join our team? We might have a position for you! Whether
                you are a student, a graduate, or a professional, explore our
                available careers and start your journey with us!
              </p>

              <router-link to="/careers" class="learn-more-r"
                >LEARN MORE</router-link
              >
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>

    <b-container fluid id="contact-us" v-prlx.background="{ speed: 0.1 }">
      <div class="container_1920">
        <div class="box-t">
          <h5><span>CONTACT US</span></h5>
          <h2>GET IN TOUCH</h2>
          <p>
            Feel free to contact us regarding our services or to pitch a
            project, as our customer service center works for you. Rest assured
            that we will quickly get back to you.
          </p>
          <b-row>
            <b-col cols="6" sm="6" md="3" lg="3" xl="3">
              <div class="cont-v mb-6">
                <router-link to="/contact-rs">SERBIA</router-link>
              </div>
            </b-col>
            <b-col cols="6" sm="6" md="3" lg="3" xl="3">
              <div class="cont-v mb-6">
                <router-link to="/contact-ca">CANADA</router-link>
              </div>
            </b-col>
            <b-col cols="6" sm="6" md="3" lg="3" xl="3">
              <div clas="cont-v">
                <router-link to="/contact-nh">NETHERLANDS</router-link>
              </div>
            </b-col>
            <b-col cols="6" sm="6" md="3" lg="3" xl="3">
              <div class="cont-v">
                <router-link to="/contact-ch">SWITZERLAND</router-link>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import carousel1 from "./Carousel";

export default {
  components: {
    carousel1,
  },
  data() {
    return {
      cartoon_video: require("@/assets/video/Cartoon_video.mp4"),
      main: require("@/assets/img/background-main.webp"),

      Cartoon_video: require("@/assets/video/Cartoon-home.mp4"),

      WebApp: require("../assets/img/services/ic_web.webp"),
      Mobile: require("@/assets/img/services/ic_mobile.webp"),
      Quality: require("../assets/img/services/ic_quality.webp"),
      Windows: require("../assets/img/services/ic_windows.webp"),
      Gaming: require("../assets/img/services/ic_gaming.webp"),
      Ux: require("../assets/img/services/ic_ux.webp"),

      applications: require("@/assets/img/img-applications.webp"),
      websites: require("@/assets/img/img-websites.webp"),
      careers: require("@/assets/img/img-work-with-us.webp"),
      android_color: require("../assets/img/Technologies/android-logo.webp"),
      angular_color: require("../assets/img/Technologies/angular-logo.webp"),
      flutter_color: require("../assets/img/Technologies/flutter-logo.webp"),
      ios_color: require("../assets/img/Technologies/ios-logo.webp"),
      laravel_color: require("../assets/img/Technologies/laravel-logo.webp"),
      net_color: require("../assets/img/Technologies/net-logo.webp"),
      php_color: require("../assets/img/Technologies/php-logo.webp"),
      react_color: require("../assets/img/Technologies/react-logo.webp"),
      unity_color: require("../assets/img/Technologies/unity-logo.webp"),
      vue_color: require("../assets/img/Technologies/vue-logo.webp"),
      w: window.innerWidth / 2,
      h: window.innerHeight / 2,
      mouseX: 0,
      mouseY: 0,
      depth1: 0,
      depth2: 0,
      depth3: 0,
    };
  },
  methods: {
    paralax: function (e) {
      this.depth1 = `${50 - (e.clientX - this.w) * 0.005}% ${
        (-100 - (e.clientY - this.h) * 0.005) * -1
      }%`;
      document.querySelector(
        "#main-img"
      ).style.backgroundPosition = this.depth1;
    },
  },
};
</script>
