<template>
  <div id="WebsiteDetails">
    <b-container class="description">
      <h5><span>WEBSITE</span></h5>
      <h2>PALADINS OF THE GALAXY</h2>
      <p>Are you ready to defend the galaxy?</p>
      <p>
        Do you have what it takes to save the galaxy? In this unique,
        action-packed multiplayer shooter, you will use all your skills to stay
        on top. As a Paladin, you have to defend your rank and reputation, but
        also your cosmic tribe.
      </p>
      <p>
        Playing with your friends or against random online opponents is a
        challenge, but thankfully, you have powerful weapons and abilities,
        including a double jump or invisibility. Test out all the characters, as
        they all have different backgrounds and use specific weapon sets. Dive
        deeper into the world of Paladins of the Galaxy by checking the website
        and downloading the game!
      </p>
    </b-container>

    <b-container class="stores">
      <b-container>
        <div class="store">
          <a
            target="_blank"
            rel="noreferrer"
            href="http://paladins.nswebdevelopment.com/"
            class="btn"
            >WEBSITE</a
          >
        </div>
      </b-container>
    </b-container>

    <b-container fluid class="details">
      <div class="container_1920">
        <b-row>
          <b-col cols="12" sm="12" md="12" xl="6" lg="6">
            <b-img
              fluid
              class="img_w"
              :src="phone1"
              alt="Paladins of the Galaxy"
            ></b-img>
          </b-col>
          <b-col cols="12" sm="12" md="12" xl="6" lg="6" class="detail">
            <b-container>
              <h5><span>PRODUCION</span></h5>
              <p>NSWD</p>
            </b-container>
            <b-container>
              <h5><span>GENRE</span></h5>
              <p>Software Development, Mobile Development, SEO</p>
            </b-container>
            <b-container>
              <h5><span>DURATION</span></h5>
              <p>C#, HTML & CSS, XML, Java, Android</p>
            </b-container>
            <b-container>
              <h5><span>YEAR</span></h5>
              <p>2017</p>
            </b-container>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phone1: require("@/assets/img/web/paladins.webp"),
    };
  },
};
</script>
