<template>
  <div id="PageWebsite">
    <b-container class="description">
      <h5><span>WEBSITES</span></h5>
      <h2>CUSTOM WEBSITES</h2>
      <p>
        We use agile methodologies & the latest technology to develop superior
        applications and take your business to the next level.
      </p>
    </b-container>
    <b-container fluid class="p-0">
      <b-container class="products">
        <b-row>
          <b-col cols="6">
            <router-link to="/websites/top-tens">
              <b-img fluid :src="TopTens" alt="TopTens"> </b-img>
              <div class="title_div">
                <h2>TOP TENS</h2>
              </div>
            </router-link>
          </b-col>

          <b-col cols="6">
            <router-link to="/websites/disinfection-door">
              <b-img fluid :src="DisinfectionDoor" alt="Desinfection door">
              </b-img>
              <div class="title_div">
                <h2>DISINFECTION DOOR</h2>
              </div>
            </router-link>
          </b-col>

          <b-col cols="6">
            <router-link to="/websites/prometheus">
              <b-img fluid :src="Prometheus" alt="Prometheus"> </b-img>
              <div class="title_div">
                <h2>PROMETHEUS</h2>
              </div>
            </router-link>
          </b-col>

          <b-col cols="6">
            <router-link to="/websites/truly-public">
              <b-img fluid :src="Esar" alt="Esar"></b-img>
              <div class="title_div">
                <h2>TRULY PUBLIC</h2>
              </div>
            </router-link>
          </b-col>

          <b-col cols="6">
            <router-link to="/websites/wolf-media">
              <b-img fluid :src="InSight" alt="Wolfmedia"></b-img>
              <div class="title_div">
                <h2>WOLF MEDIA</h2>
              </div>
            </router-link>
          </b-col>
          <b-col cols="6">
            <router-link to="/websites/wolf-distribution">
              <b-img fluid :src="FoodOrderong" alt="Wolfdistribution"></b-img>
              <div class="title_div">
                <h2>WOLF DISTRIBUTION</h2>
              </div>
            </router-link>
          </b-col>

          <b-col cols="6">
            <router-link to="/websites/coffee-beans">
              <b-img fluid :src="CoffeBocca" alt="Coffee Beans"></b-img>
              <div class="title_div">
                <h2>COFFEE BEANS</h2>
              </div>
            </router-link>
          </b-col>
          <b-col cols="6">
            <router-link to="/websites/paladins-of-the-galaxy">
              <b-img fluid :src="Paladins" alt="Paladin web page"></b-img>
              <div class="title_div">
                <h2>PALADINS OF THE GALAXY</h2>
              </div>
            </router-link>
          </b-col>

          <b-col cols="6">
            <router-link to="/websites/golden-five">
              <b-img fluid :src="Spms" alt="Golden five web page"></b-img>
              <div class="title_div">
                <h2>GOLDEN FIVE</h2>
              </div>
            </router-link>
          </b-col>
          <b-col cols="6">
            <router-link to="/websites/block-games">
              <b-img fluid :src="BlockGame" alt="Block games"></b-img>
              <div class="title_div">
                <h2>BLOCK GAMES</h2>
              </div>
            </router-link>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      CoffeBocca: require("@/assets/img/web/coffe-beans.webp"),
      Paladins: require("@/assets/img/web/paladins.webp"),
      BlockGame: require("@/assets/img/web/block-games.webp"),
      Prometheus: require("@/assets/img/web/prometheus.webp"),
      Esar: require("@/assets/img/web/truly-public.webp"),
      InSight: require("@/assets/img/web/wolf-media.webp"),
      FoodOrderong: require("@/assets/img/web/wolf-distribution.webp"),
      Spms: require("@/assets/img/web/golden5.webp"),
      TopTens: require("@/assets/img/web/top-tens.webp"),
      DisinfectionDoor: require("@/assets/img/web/dezinfekciona-vrata.webp"),
    };
  },
};
</script>
