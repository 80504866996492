<template>
  <div id="ApplicationD">
    <b-container class="description">
      <h5><span>APPLICATION</span></h5>
      <h2>TIME TRACKING</h2>
      <p>
        Organize the working hours and pause duration by creating shifts, non-working days, and holidays. 
        Arrange the workforce in teams, give them a group assignment and view your company’s business in real-time. 
        The main difference between manual time tracking and time tracking with our application is data accuracy. 
        By using it, you will avoid manual time-track reporting bringing numerous and potentially expensive errors. 
        Although still in use, paper timesheets are outdated, while work time management with RFID cards is 
        complicated and usually costly, as it requires high-priced installation of card readers, servers, 
        and printing out the cards.
      </p>
    </b-container>

    <div class="suportby">
      <h2>SUPPORTED BY</h2>
      <div class="logo-img">
        <a target="_blank" rel="noreferrer" href="https://mts.rs/">
          <b-img :src="mtslogo" alt="MTS Srbija"></b-img>
        </a>
        <a target="_blank" rel="noreferrer" href="https://mtel.ba/">
          <b-img :src="mtel" alt="MTel"></b-img>
        </a>
        <a target="_blank" rel="noreferrer" href="https://www.telekom.mk/">
          <b-img :src="makedonski" alt="Telekom Makedonija"></b-img>
        </a>
        <a target="_blank" rel="noreferrer" href="http://ftn.uns.ac.rs/691618389/fakultet-tehnickih-nauka">
          <b-img :src="ftn" alt="ftn-logo"></b-img>
        </a>
      </div>
    </div>

    <b-container class="stores">
      <b-container>
        <div class="store">
          <a rel="noreferrer" href="#" class="btn"
            >WEBSITE</a
          >
        </div>
      </b-container>
      <b-container>
        <div class="store_img">
        <div class="store">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://apps.apple.com/us/app/simula-time-track/id1445561620?ls=1"
            class="store"
          >
            <b-img :src="appstory" alt="Apple logo"></b-img>
          </a>
        </div>
        <div class="store">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://play.google.com/store/apps/details?id=com.time_tracking.user006test.timetracking"
            class="store"
          >
            <b-img :src="playstory" alt="Playstore logo"></b-img>
          </a>
        </div>
        </div>
      </b-container>
    </b-container>

    <b-container fluid class="details">
      <div class="container_1920">
        <b-row>
          <b-col cols="12" sm="12" md="12" xl="6" lg="6">
            <div class="star">
                  <b-img alt="Time tracker" fluid :src="star"></b-img>
                </div>
            <b-img fluid class="img_w" :src="phone1" alt="Time Tracking"></b-img>
          </b-col>
          <b-col cols="12" sm="12" md="12" xl="6" lg="6" class="detail">
            <b-container>
              <h5><span>CLIENT</span></h5>
              <p>Simula, Serbia</p>
            </b-container>
            <b-container>
              <h5><span>SERVICES</span></h5>
              <p>Software Development, Mobile Development, SEO</p>
            </b-container>
            <b-container>
              <h5><span>TECHNOLOGIES</span></h5>
              <p>C#, HTML & CSS, XML, Java, Android</p>
            </b-container>
            <b-container>
              <h5><span>YEAR</span></h5>
              <p>2018</p>
            </b-container>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phone1: require("@/assets/img/aplication/time-tracking.webp"),
      appstory: require("@/assets/img/AppStore.webp"),
      playstory: require("@/assets/img/GooglePlay.webp"),
      mtslogo: require("@/assets/img/Logos/mts-logo.webp"),
      mtel: require("@/assets/img/Logos/mtel-logo.webp"),
      makedonski: require("@/assets/img/Logos/makedonski-telekom-logo.webp"),
      ftn: require("@/assets/img/Logos/ftn-logo.webp"),
      star: require("@/assets/img/star-solid.webp"),
    };
  },
};
</script>
