<template>
  <div id="WebsiteDetails">
    <b-container class="description">
      <h5><span>WEBSITE</span></h5>
      <h2>BLOCK GAMES</h2>
      <p>
        If you love BlockGames, now you can have them all in one place! These
        mobile titles feature popular pixel graphics and gameplay that is easy
        to learn but hard to master. These titles are unique, featuring
        radically different gameplay. If you love first-person shooters, go in
        action Rambo style, or have your friends join for a session and see who
        gets the most kills. On the other end of the spectrum, try Hide & Seek
        and find your rivals. Quicksand is the last man standing mode, and
        Parkour needs you to be skillful and quick. Still, there's more, so you
        can fight the zombies, save the world from the terrorist, or even wage
        intergalactic wars. There are tons of challenges to test your skills, so
        visit the website to learn about every title available for mobile
        platforms.
      </p>
    </b-container>

    <b-container class="stores">
      <b-container>
        <div class="store">
          <a
            target="_blank"
            rel="noreferrer"
            href="http://blockgames.org/"
            class="btn"
            >WEBSITE</a
          >
        </div>
      </b-container>
    </b-container>

    <b-container fluid class="details">
      <div class="container_1920">
        <b-row>
          <b-col cols="12" sm="12" md="12" xl="6" lg="6">
            <b-img fluid class="img_w" :src="phone1" alt="Block games"></b-img>
          </b-col>
          <b-col cols="12" sm="12" md="12" xl="6" lg="6" class="detail">
            <b-container>
              <h5><span>CLIENT</span></h5>
              <p>BlockGames</p>
            </b-container>
            <b-container>
              <h5><span>SERVICE</span></h5>
              <p>Software Development, Mobile Development, SEO</p>
            </b-container>
            <b-container>
              <h5><span>TECHNOLOGIES</span></h5>
              <p>C#, HTML & CSS, XML, Java, Android</p>
            </b-container>
            <b-container>
              <h5><span>YEAR</span></h5>
              <p>2017</p>
            </b-container>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phone1: require("@/assets/img/web/block-games.webp"),
    };
  },
};
</script>
