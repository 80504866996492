<template>
  <div id="ApplicationD">
    <b-container class="description">
      <h5><span>APPLICATION</span></h5>
      <h2>ESAR</h2>
      <p>
        ESAR is the Arabian peer-to-peer carsharing service. Besides a vast number of web pages, 
        we developed a mobile application for Android & iOS and more than 20 support emails. 
        As this is a service for the Arabian market, special attention had to be paid to the 
        UI elements' layout to work with the Arabic language. However, everything also had to 
        look pleasant and functional in English, the service's secondary language.
      </p>
    </b-container>

    <b-container class="stores">
      <b-container>
        <div class="store">
          <a target="_blank" rel="noreferrer" href="https://www.esarcar.com/" class="btn">WEBSITE</a>
        </div>
      </b-container>
      <b-container>
        <div class="store_img">
        <div class="store">
          <a target="_blank" rel="noreferrer" href="https://apps.apple.com/us/app/esar/id1393026934?ls=1" class="store">
            <b-img :src="appstory" alt="Appstory"></b-img>
          </a>
        </div>
        <div class="store">
          <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.esarcar.esar" class="store">
            <b-img :src="playstory" alt="Playstory"></b-img>
          </a> 
        </div>
        </div>
      </b-container>
    </b-container>

    <b-container fluid class="details">
      <div class="container_1920">
      <b-row>
        <b-col cols="12" sm="12" md="12" xl="6" lg="6">
          <b-img fluid class="img_w" :src="phone1" alt="Esar"></b-img>
        </b-col>
        <b-col cols="12" sm="12" md="12" xl="6" lg="6" class="detail">
          <b-container>
            <h5><span>CLIENT</span></h5>
            <p>Esar, Saudi Arabia</p>
          </b-container>
          <b-container>
            <h5><span>SERVICES</span></h5>
            <p>Software Development, Mobile Development, SEO</p>
          </b-container>
          <b-container>
            <h5><span>TECHNOLOGIES</span></h5>
            <p>C#, HTML & CSS, XML, Java, Android</p>
          </b-container>
          <b-container>
            <h5><span>YEAR</span></h5>
            <p>2018</p>
          </b-container>
        </b-col>
      </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phone1: require("@/assets/img/aplication/esar.webp"),
      appstory: require("@/assets/img/AppStore.webp"),
      playstory: require("@/assets/img/GooglePlay.webp")
    };
  }
};
</script>
