<template>
  <div id="WebsiteDetails">
    <b-container class="description">
      <h5><span>WEBSITE</span></h5>
      <h2>WOLF DISTRIBUTION</h2>
      <p>
        As music and video ecosystems are becoming more complex, the
        distribution requires more resources. Wolf Distribution provides quality
        delivery service, supporting various media platforms. These include
        cable TV, linear TV, IPTV, and every SVOD, TVOD, and AVOD platforms
        (YouTube, Netflix, Google Play, iTunes, and others.) This unique service
        interprets and collects data points using the latest technology,
        transforming them into valuable insights.
      </p>
    </b-container>

    <b-container class="stores">
      <b-container>
        <div class="store">
          <a
            
            rel="noreferrer"
            href=""
            class="btn"
            >WEBSITE</a
          >
        </div>
      </b-container>
    </b-container>

    <b-container fluid class="details">
      <div class="container_1920">
        <b-row>
          <b-col cols="12" sm="12" md="12" xl="6" lg="6">
            <b-img
              fluid
              class="img_w"
              :src="phone1"
              alt="Wolf Distribution"
            ></b-img>
          </b-col>
          <b-col cols="12" sm="12" md="12" xl="6" lg="6" class="detail">
            <b-container>
              <h5><span>CLIENT</span></h5>
              <p>NSWD</p>
            </b-container>
            <b-container>
              <h5><span>SERVICE</span></h5>
              <p>Software Development, Mobile Development, SEO</p>
            </b-container>
            <b-container>
              <h5><span>TECHNOLOGIES</span></h5>
              <p>C#, HTML & CSS, XML, Java, Android</p>
            </b-container>
            <b-container>
              <h5><span>YEAR</span></h5>
              <p>2019</p>
            </b-container>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phone1: require("@/assets/img/web/wolf-distribution.webp"),
    };
  },
};
</script>
