<template>
  <div id="ApplicationD">
    <b-container class="description">
      <h5><span>APPLICATION</span></h5>
      <h2>SPMS</h2>
      <p>
        Smart Palm is a huge electric tree that allows your community to charge their electronic machines for 
        free. It gets power as any tree, from the Sun. And on it you can install an electric commercial which 
        will be amusement for the ones that are recharging. Our Smart Palm Management System ( SPMS ) manages 
        these trees. With only few click you can change commercials, see how are the trees doing, switch them off, 
        turn them on… All from the comfort of your house/office.
      </p>
    </b-container>

    <b-container class="stores">
      <b-container>
        <div class="store">
          <a target="_blank" rel="noreferrer" href="#" class="btn">WEBSITE</a>
        </div>
      </b-container>
      <b-container>
        <div class="store">
          <a target="_blank" rel="noreferrer" href="#" class="store">
            <b-img :src="appstory" alt="Appstory"></b-img>
          </a>
        </div>
        <div class="store">
          <a target="_blank" rel="noreferrer" href="#" class="store">
            <b-img :src="playstory" alt="Playstory"></b-img>
          </a>  
        </div>
      </b-container>
    </b-container>

    <b-container fluid class="details">
      <div class="container_1920">
      <b-row>
        <b-col cols="12" sm="12" md="12" xl="6" lg="6">
          <b-img fluid class="img_w" :src="phone1" alt="SPMS"></b-img>
        </b-col>
        <b-col cols="12" sm="12" md="12" xl="6" lg="6" class="detail">
          <b-container>
            <h5><span>CLIENT</span></h5>
            <p>NS Web Development, Serbia</p>
          </b-container>
          <b-container>
            <h5><span>SERVICES</span></h5>
            <p>Software Development, Mobile Development, SEO</p>
          </b-container>
          <b-container>
            <h5><span>TECHNOLOGIES</span></h5>
            <p>C#, HTML&CSS, XML, Java,Andorid</p>
          </b-container>
          <b-container>
            <h5><span>YEAR</span></h5>
            <p>2015</p>
          </b-container>
        </b-col>
      </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phone1: require("@/assets/img/aplication/spms.jpg"),
      appstory: require("@/assets/img/AppStore.webp"),
      playstory: require("@/assets/img/GooglePlay.webp")
    };
  }
};
</script>
