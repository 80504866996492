<template>
  <div id="ApplicationD">
    <b-container class="description">
      <h5><span>APPLICATION</span></h5>
      <h2>FOOD ORDERING</h2>
      <p>
        Food Ordering is an Android, iOS, and desktop app perfect for a restaurant or restaurant chain owner. 
        This app is the best tool if you want to offer more to your clientele by rewarding loyal regulars and 
        reaching new customers. Thanks to this app, clients will come back more often instead of going somewhere else!
      </p>
    </b-container>

    <b-container class="stores">
      <b-container>
        <div class="store">
          <a target="_blank" rel="noreferrer" href="https://foodordering.eu/" class="btn"
            >WEBSITE</a
          >
        </div>
      </b-container>
      <b-container>
        <div class="store_img">
        <div class="store">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://apps.apple.com/us/app/keynote/id1079308247"
            class="store"
          >
            <b-img :src="appstory" alt="Appstore logo"></b-img>
          </a>
        </div>
        </div>
      </b-container>
    </b-container>

    <b-container fluid class="details">
      <div class="container_1920">
        <b-row>
          <b-col cols="12" sm="12" md="12" xl="6" lg="6">
            <b-img fluid class="img_w" :src="phone1" alt="Food Ordering"></b-img>
          </b-col>
          <b-col cols="12" sm="12" md="12" xl="6" lg="6" class="detail">
            <b-container>
              <h5><span>CLIENT</span></h5>
              <p>NS Web Development, Serbia</p>
            </b-container>
            <b-container>
              <h5><span>SERVICES</span></h5>
              <p>Software Development, Mobile Development, SEO</p>
            </b-container>
            <b-container>
              <h5><span>TECHNOLOGIES</span></h5>
              <p>C#, HTML & CSS, XML, Java, Android</p>
            </b-container>
            <b-container>
              <h5><span>YEAR</span></h5>
              <p>2015</p>
            </b-container>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phone1: require("@/assets/img/aplication/food-ordering.webp"),
      appstory: require("@/assets/img/AppStore.webp"),
      playstory: require("@/assets/img/GooglePlay.webp"),
    };
  },
};
</script>
