<template>
  <div class="App">
    <AppHeader></AppHeader>
    <router-view></router-view>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import "./assets/scss/main.scss";
import "leaflet/dist/leaflet.css";
import AppHeader from "./components/AppHeader.vue";
import AppFooter from "./components/AppFooter.vue";

export default {
  components: {
    AppHeader,
    AppFooter,
  },
  name: "App",
  metaInfo() {
    return {
      title: "NS Web Development | Outsourcing Company",
      meta: [
        {
          name: "keywords",
          content:
            "nswebdevelopment.com, NSWD, NS Web Development Novi Sad LLC, outsourcing blockchain consulting, IT kompanija Novi Sad, IT Poslovi, outsourcing poslovi nswd blockchain",
        },
        {
          name: "description",
          content:
            "nswebdevelopment.com | NS Web Development Novi Sad LLC, Outsourcing Company",
        },
        { name: "author", content: "NSWD NS Web Development Novi Sad LLC" },
        {
          property: "og:title" ,
          content: "NS Web Development | Outsourcing company",
        },
        {
          property: "og:image",
          content: "https://nswebdevelopment.com/img/nswd.8ec2c90b.png",
        },
        { property: "og:image:type", content: "image/png" },
        { property: "og:image:width", content: "300" },
        { property: "og:image:height", content: "300" },
        { property: "og:url", content: "https://nswebdevelopment.com/" },
        { property: "og:site_name", content: "NS Web Development" },
        { property: "og:type", content: "website" },
        { property: "og:locale", content: "en" },
        {
          name: "og:description",
          content:
            "nswebdevelopment.com | NS Web Development Novi Sad LLC, Outsourcing Company",
        },
        { name: "theme-color", conten: "#EF5733" },
        { name: "robots", content: "index, follow" },
        {
          name: "twitter:title",
          content:
            "nswebdevelopment.com | NS Web Development Novi Sad LLC, Outsourcing Company",
        },
        {
          name: "twitter:image",
          content: "https://nswebdevelopment.com/img/nswd.2df5c310.png",
        },
        { name: "twitter:url", content: "https://nswebdevelopment.com/" },
        { name: "twitter:card", content: "https://nswebdevelopment.com/" },
      ],
      link: [
        { id: "canonical", rel: "canonical", href: "https://nswebdevelopment.com/" },
        { rel: "favicon", href: "favicon.ico" }
      ],
    };
  },
};
</script>

<style>
* {
  box-sizing: border-box;
  margin: 0px;
}

html,
body {
  width: 100%;
}

.app {
  width: 100%;
  margin: 0px;
  padding: 0px;
}
</style>
