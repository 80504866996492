<template>
  <div>
    <footer id="AppFooter">
      <div class="container_1920">
        <b-row>
          <b-col cols="12" sm="12" md="12" lg="3" xl="3">
            <router-link to="/">
            <b-img alt="NS Web Development logo" class="logo" :src="logo"></b-img>
            </router-link>
            <b-row class="icon-div">
              <b-col cols="2">
                <a
                  href="https://www.facebook.com/nswebdev/?ref=br_rs"
                  target="_blank"
                >
                  <b-img alt="facebook" class="img_white" :src="facebook"></b-img>
                  <b-img alt="facebook" class="img_color" :src="facebook_color"></b-img>
                </a>
              </b-col>
              <b-col cols="2">
                <a
                  href="https://www.linkedin.com/company/ns-web-development-doo-novi-sad/"
                  target="_blank"
                >
                  <b-img alt="linkdin" class="img_white" :src="linkdin"></b-img>
                  <b-img alt="linkdin" class="img_color" :src="linkdin_color"></b-img>
                </a>
              </b-col>
              <b-col cols="2">
                <a
                  href="https://www.youtube.com/channel/UCIdOs7nEtpaK7sXNniqOTAw"
                  target="_blank"
                >
                  <b-img alt="youtube logo" class="img_white" :src="youtube"></b-img>
                  <b-img alt="youtube logo" class="img_color" :src="youtube_color"></b-img>
                </a>
              </b-col>
              <b-col cols="2">
                <a
                  href="https://www.instagram.com/nswebdevelopment/"
                  target="_blank"
                >
                  <b-img alt="instagram logo" class="img_white" :src="instagram"></b-img>
                  <b-img alt="instagram logo" class="img_color" :src="instagram_color"></b-img>
                </a>
              </b-col>
              <b-col cols="2">
                <a
                  href="https://twitter.com/NSWD_"
                  target="_blank"
                >
                  <b-img alt="x logo" class="img_white" :src="x"></b-img>
                  <b-img alt="x logo" class="img_color" :src="x_color"></b-img>
                </a>
              </b-col>

            </b-row>
          </b-col>
          <b-col
            cols="12"
            sm="12"
            md="12"
            lg="2"
            xl="2"
            offset="0"
            offset-sm="0"
            offset-md="0"
            offset-lg="2"
            offset-xl="2"
            order="6"
            order-sm="6"
            order-md="6"
            order-lg="1"
            order-xl="1"
          >
            <h6>COMPANY</h6>
            <p><router-link to="/services">Services</router-link></p>
            <p><router-link to="/team">Our team</router-link></p>
            <p><router-link to="/careers">Careers</router-link></p>
          </b-col>
          <b-col
            cols="12"
            sm="12"
            md="12"
            lg="2"
            xl="2"
            order="1"
            order-sm="1"
            order-md="1"
            order-lg="6"
            order-xl="6"
          >
            <h6>PRODUCTS</h6>
            <p><router-link to="/applications">Applications</router-link></p>
            <p><router-link to="/games">Games</router-link></p>
            <p><router-link to="/cartoons">Cartoons</router-link></p>
            <p><router-link to="/websites">Websites</router-link></p>
          </b-col>
          <b-col
            cols="12"
            sm="12"
            md="12"
            xl="3"
            lg="3"
            offset-xl="0"
            order="12"
            order-sm="12"
            order-md="12"
          >
            <h6>CONTACT</h6>
            <p class="address">
              NS Web Development LLC.<br />
              Dr. Ribara 15<br />
              21000 Novi Sad, Serbia
            </p>
            <div class="btn-n">
              <router-link class="footer_contact" to="/contact-rs"
                >CONTACT US</router-link
              >
            </div>
          </b-col>
        </b-row>
      </div>
    </footer>
    <div class="reserved">
      <p>© {{new Date().getFullYear()}} | NS Web Development | All rights reserved</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      facebook: require("@/assets/img/ic_facebook_white.svg"),
      linkdin: require("@/assets/img/ic_linkedin_white.svg"),
      youtube: require("@/assets/img/ic_youtube_white.svg"),
      x: require("@/assets/img/ic_x_white.svg"),
      instagram: require("@/assets/img/ic_instagram_white.svg"),
      logo: require("@/assets/img/nswd.webp"),
      facebook_color: require("@/assets/img/ic_facebook_colour.svg"),
      linkdin_color: require("@/assets/img/ic_linkedin_colour.svg"),
      youtube_color: require("@/assets/img/ic_youtube_colour.svg"),
      x_color: require("@/assets/img/ic_x_colour.svg"),
      instagram_color: require("@/assets/img/ic_instagram_colour.svg")
    };
  }
};
</script>
