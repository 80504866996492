<template>
  <div id="ApplicationD">
    <b-container class="description">
      <h5><span>APPLICATION</span></h5>
      <h2>NI ANALYTICS</h2>
      <p>
        Ni Analytics aims to develop a novel, modular, full-scope clinical trial management and analysis software 
        allowing the user to efficiently design, plan, manage and control multiple prospective, double-blind, 
        randomized, multi-centric clinical studies. Capture, manage and analyze all data and early detect adverse developments. 
        Conduct regulatory compliance and submission of required documents. Main client groups include biopharmaceutical companies, 
        clinical research organizations (CRO), private and state-owned biopharmaceutical research organizations and institutes, 
        as well as hospitals.
      </p>
    </b-container>

    <b-container class="stores">
      <b-container>
        <div class="store">
          <a rel="noreferrer" href="" class="btn">WEBSITE</a>
        </div>
      </b-container>
    </b-container>

    <b-container fluid class="details">
      <div class="container_1920">
        <b-row>
          <b-col cols="12" sm="12" md="12" xl="6" lg="6">
            <b-img fluid class="img_w" :src="phone1" alt="Ni Analytics"></b-img>
          </b-col>
          <b-col cols="12" sm="12" md="12" xl="6" lg="6" class="detail">
            <b-container>
              <h5><span>CLIENT</span></h5>
              <p>NS Web Development, Serbia</p>
            </b-container>
            <b-container>
              <h5><span>SERVICES</span></h5>
              <p>Software Development, Mobile Development, SEO</p>
            </b-container>
            <b-container>
              <h5><span>TECHNOLOGIES</span></h5>
              <p>C#, HTML & CSS, XML, Java, Android</p>
            </b-container>
            <b-container>
            <h5><span>YEAR</span></h5>
            <p>2015</p>
          </b-container>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phone1: require("@/assets/img/aplication/ni-analytics.webp"),
      appstory: require("@/assets/img/AppStore.webp"),
      playstory: require("@/assets/img/GooglePlay.webp"),
    };
  },
};
</script>
