<template>
  <div id="ApplicationD">
    <b-container class="description">
      <h5><span>APPLICATION</span></h5>
      <h2>DUNAVSKI PARK</h2>
      <p>
        Danube Park or Danube Park Plant World is an android application that allows users to learn more about plants that inhabit Danube Park. 
        By scanning the QR code user opens a screen with a photos of the requested plant, its scientific classification, and a description of the plant itself. The application also has a map with the location of specific plants
      </p>
    </b-container>

    <b-container class="stores">
      <b-container>
        <div class="store">
          <a  class="btn">WEBSITE</a>
        </div>
      </b-container>
      <b-container>
        <div class="store">
          <a target="_blank" href="#" class="store">
            <b-img :src="appstory" alt="Appstore logo"></b-img>
          </a>
        </div>
        <div class="store">
          <a target="_blank" href="#" class="store">
            <b-img :src="playstory" alt="Playstory logo"></b-img>
          </a>
        </div>
      </b-container>
    </b-container>

    <b-container fluid class="details">
      <div class="container_1920">
      <b-row>
        <b-col cols="12" sm="12" md="12" lg="6" xl="6" p-sm="0">
           <b-img fluid class="img_w" :src="phone1" alt="Dunavski park"></b-img>
        </b-col>
       <b-col cols="12" sm="12" md="12" lg="6" xl="6" class="detail">
          <b-container>
             <h5><span>CLIENT</span></h5>
            <p>NSWD</p>
          </b-container>
          <b-container>
            <h5><span>SERVICES</span></h5>
            <p>Software Development, Mobile Development, SEO</p>
          </b-container>
          <b-container>
            <h5><span>TECHNOLOGIES</span></h5>
            <p>C#, HTML & CSS, XML, Java, Android</p>
          </b-container>
          <b-container>
            <h5><span>YEAR</span></h5>
            <p>2017</p>
          </b-container>
        </b-col>
      </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phone1: require("@/assets/img/aplication/dunavski-park.webp"),
      appstory: require("@/assets/img/AppStore.webp"),
      playstory: require("@/assets/img/GooglePlay.webp")
    };
  }
};
</script>
