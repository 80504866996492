<template>
  <div id="PageCareer">
    <b-container>
      <h5><span>CAREERS</span></h5>
      <h2>.NET DEVELOPER</h2>
      <p>
        As a team member, you will be working on various projects ranging from mobile entertainment applications to 
        back-office applications for various customers in multiple industries. All projects have one thing in common: 
        they are created for the Western European lifecycle: from functional design to implementation.
      </p>
      <router-link to="/careers-net-developer">DETAILS</router-link>
    </b-container>

    <b-container>
      <h2>PHP DEVELOPER</h2>
      <p>
        PHP Developer is responsible for data exchange between the users and the servers and managing back-end services. 
        Your primary authority includes defining and maintaining the main database, securing responsiveness, and top performance. 
        Also, you will develop complete server-side logic and implement front-end elements created by other team members. 
        For that reason, it's necessary to have a basic knowledge of front-end development.
      </p>
      <router-link to="/careers-php-developer">DETAILS</router-link>
    </b-container>

    <b-container>
      <h2>UNITY OR UNITY 3D DEVELOPER</h2>
      <p>
        As a team member, you'll work on creating Unity-based framework games for various platforms. Your primary competence 
        will be to build appealing games by implementing concepts, design ideas, and requirements. A dedication to creating a 
        quality product with sophisticated design and solving problems with other team members is of the essence.
      </p>
      <router-link to="/careers-unity-developer">DETAILS</router-link>
    </b-container>

    <b-container v-show="showerM" class="alert alert-success" role="alert">
      <p>Send success!</p>
    </b-container>
    <b-container fluid id="ApplayPosition" v-show="!shower">
      <div class="CareerContact">
        <h2>APPLY FOR POSITION</h2>
        <form @submit.prevent="send">
          <b-form-group>
            <b-form-input
              type="text"
              name="fname"
              v-model="form.fname"
              v-validate="'required|alpha'"
              :class="{ 'is-invalid': errors.first('fname') }"
              placeholder="First name*"
            ></b-form-input>
            <b-form-invalid-feedback id="fnameF"
              >Frst name required !</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group>
            <b-form-input
              type="text"
              name="lname"
              v-model="form.lname"
              v-validate="'required|alpha'"
              :class="{ 'is-invalid': errors.first('lname') }"
              placeholder="Last name*"
            ></b-form-input>
            <b-form-invalid-feedback
              >Last name required !</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group>
            <b-form-input
              type="text"
              name="email"
              v-model="form.email"
              v-validate="'required|email'"
              :class="{ 'is-invalid': errors.first('email') }"
              placeholder="Email*"
            ></b-form-input>
            <b-form-invalid-feedback
              >Email address required !</b-form-invalid-feedback
            >
          </b-form-group>
          <b-form-group>
            <b-row class="btn-file-div">
              <b-col cols="12" sm="12" md="6" lg="6" xl="6">
                <b-form-group>
                  <span class="btn-file"
                    >{{ cvButtons
                    }}<input
                      type="file"
                      id="cv_file"
                      ref="cv_File"
                      v-on:change="cvFileUpload()"
                    />
                  </span>
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="12" md="6" lg="6" xl="6">
                <b-form-group>
                  <span v-show="cover" class="btn-file btn-file-r"
                    >ATACH COVER LETTER<input
                      type="file"
                      id="cover_file"
                      ref="cover_File"
                      v-on:change="coverFileUpload()"
                    />
                  </span>
                  <span
                    class="btn-file btn-file-r"
                    v-show="!cover"
                    @click="coverFileUpload()"
                    >REMOVE COVER LETTER</span
                  >
                </b-form-group>
              </b-col>
            </b-row>
          </b-form-group>

          <b-form-group>
            <b-form-textarea
              v-model="form.message"
              placeholder="Link to your work, projects, videos, code etc."
              rows="6"
              max-rows="9"
            ></b-form-textarea>
          </b-form-group>

          <b-form-group>
            <div class="btn btn_bottom">
              <b-button
                :class="{ 'btn-a': !terms, 'btn-b': terms }"
                type="submit"
                variant="primary"
                :disabled="terms"
                >APPLY</b-button
              >
            </div>
          </b-form-group>
        </form>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        fname: "",
        lname: "",
        email: "",
        message: "",
      },
      shower: true,
      showerM: false,
      cvFile: "",
      coverFile: "",
      terms: true,
      cover: true,
      cvButtons: "ATACH CV*",
    };
  },
  methods: {
    cvFileUpload() {
      this.cvFile = this.$refs.cv_File.files[0];

      if (this.cvFile === "") {
        this.terms = true;
        this.cvButtons = "ATACH CV*";
        this.buttonColor = "class='btn-b'";
      } else {
        this.terms = false;
        this.cvButtons = this.cvFile.name;
        this.buttonColor = "class='btn-b'";
      }
    },
    coverFileUpload() {
      if (this.cover) {
        this.coverFile = this.$refs.cover_File.files[0];
        this.cover = false;
      } else {
        this.coverFile = "";
        this.cover = true;
      }
      console.log(this.coverFile.name);
    },
    send() {
      let formData = new FormData();

      if (this.cvFile != "") {
        formData.append("cvFile", this.cvFile);
      }
      if (this.coverFile != "") {
        formData.append("coverFile", this.coverFile);
      }
      formData.append("fname", this.form.fname);
      formData.append("lname", this.form.lname);
      formData.append("email", this.form.email);
      formData.append("message", this.form.message);

      this.$validator.validateAll().then((result) => {
        if (result) {
          this.axios
            .post("http://nswd.myworlds.win/careers.php", formData)
            .then((response) => {
              console.log(response);
            })
            .catch((error) => {
              console.log(error);
            });

          this.shower = true;
          this.showerM = true;
          this.clierForm();
          this.$nextTick(() => {
            this.$validator.reset();
          });
          return;
        } else {
          this.showerM = false;
          this.shower = false;
        }
      });
    },
    clierForm() {
      this.form.fname = "";
      this.form.lname = "";
      this.form.email = "";
      this.form.message = "";
      this.cvFile = "";
      this.coverFile = "";
    },
  },
};
</script>
