<template>
  <div id="PageContact">
    <b-container fluid class="bg-white">
      <b-container>
        <h5><span>CONTACT US</span></h5>
        <h2>CONTACT IN NETHERLANDS</h2>
        <p>
          Feel free to contact us regarding our services or to pitch a project,
          as our customer service center works for you. Rest assured that we
          will quickly get back to you.
        </p>
      </b-container>
    </b-container>
    <AppContact></AppContact>
    <AppMaps></AppMaps>
  </div>
</template>

<script>
import AppContact from "./AppContact";
import AppMaps from "./AppMaps";

export default {
  components: {
    AppContact,
    AppMaps,
  },
};
</script>
