<template>
  <div id="ApplicationD">
    <b-container class="description">
      <h5><span>APPLICATION</span></h5>
      <h2>THE SUCCESS PLAN</h2>
      <p>
        The Success Plan will give you immediate control of your entire financial life! 
        This includes education and tools that will ensure maximizing your money in every area. 
        Your finances will be in sync, easy to manage, and even fun, while you can easily 
        adjust everything according to your needs. Having complete control of your economics 
        allows you to live stress-free and enjoy life to the fullest! By quickly identify 
        areas that will save you thousands of dollars, you will also save something even 
        more important - your time.
      </p>
    </b-container>

    <b-container class="stores">
      <b-row>
        <b-col sm="8" md="4" xl="2" offset-sm="2" offset-md="4" offset-xl="5">
        </b-col>
      </b-row>
      <b-container>
        <div class="store">
          <a target="_blank" rel="noreferrer" href="https://thesuccessplan.com/" class="btn"
            >WEBSITE</a
          >
        </div>
      </b-container>
      
    </b-container>

    <b-container fluid class="details">
      <div class="container_1920">
        <b-row>
          <b-col cols="12" sm="12" md="12" xl="6" lg="6">
            <b-img fluid class="img_w" :src="phone1" alt="The Success Plan"></b-img>
          </b-col>
          <b-col cols="12" sm="12" md="12" xl="6" lg="6" class="detail">
            <b-container>
              <h5><span>CLIENT</span></h5>
              <p>The Success Plan Inc</p>
            </b-container>
            <b-container>
              <h5><span>SERVICES</span></h5>
              <p>Software Development, SEO</p>
            </b-container>
            <b-container>
              <h5><span>TECHNOLOGIES</span></h5>
              <p>Vue.js, .NET, Android</p>
            </b-container>
            <b-container>
              <h5><span>YEAR</span></h5>
              <p>2020</p>
            </b-container>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phone1: require("@/assets/img/aplication/The-Success-Plan.webp"),
      appstory: require("@/assets/img/AppStore.webp"),
      playstory: require("@/assets/img/GooglePlay.webp"),
    };
  },
};
</script>
