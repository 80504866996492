<template>
  <div id="PageCartoonsDetails">
    <b-container class="description">
      <h5><span>CARTOON</span></h5>
      <h2>GOLDEN FIVE</h2>
      <p>
        <b
          >Five inseparable friends from the neighborhood, Abaz, Kuan, Max,
          Rock, and Walt, are big football fans and enthusiastic supporters of
          the local football</b
        >
        club The Dragons. They do not miss a single match in the league and have
        all the flags, scarves, rattles, clappers, whistles, etc. The colors of
        Dragons are green and bright yellow, and those colors make their daily
        outfits recognizable. Our heroes became local celebrities in the school
        and the entire city because of their superb football skills united into
        the Golden Five team.
      </p>

      <p>
        The community is multinational, and so is this party of friends. The
        five belongs to the middle class and are not overly worried about their
        social status. They live everyday teenage life and enjoy their
        childhood, finishing primary school. Generally, they are nice boys and
        average pupils, and like all the other boys of their age, they don't
        care much about education, nor the future.
      </p>

      <p>
        There is a minor football league called TCL (Teenage City League) in the
        city, with "Golden Five" as one of the best teams. Every weekend, they
        play matches all over the city to improve their league standings, hoping
        to win the season. However, they are yet to achieve this.
      </p>
    </b-container>

    <b-container class="stores">
      <b-container>
        <div class="store">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.youtube.com/watch?v=ad5S8Id6kXY&ab_channel=NSWebDevelopment"
            class="btn"
            >TRAILER</a
          >
        </div>
        <div class="store">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://golden5.nswebdevelopment.com/"
            class="btn"
            >WEBSITE</a
          >
        </div>
      </b-container>
    </b-container>

    <b-container fluid class="details">
      <div class="container_1920">
        <b-row>
          <b-col cols="12" sm="12" md="12" lg="6" xl="6">
            <b-img
              fluid
              class="img_w"
              :src="goldenfive"
              alt="Golden five"
            ></b-img>
          </b-col>
          <b-col cols="12" sm="12" md="12" lg="6" xl="6" class="detail">
            <b-container>
              <h5><span>PRODUCTION</span></h5>
              <p>NSWD</p>
            </b-container>
            <b-container>
              <h5><span>GENRE</span></h5>
              <p>Sports animatied series</p>
            </b-container>
            <b-container>
              <h5><span>DURATION</span></h5>
              <p>2 seasons 13 ep x 5min</p>
            </b-container>
            <b-container>
              <h5><span>YEAR</span></h5>
              <p>2017</p>
            </b-container>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      goldenfive: require("@/assets/img/Cartoons/Golden-5.webp"),
    };
  },
};
</script>
