<template>
  <div id="WebsiteDetails">
    <b-container class="description">
      <h5><span>WEBSITE</span></h5>
      <h2>GOLDEN FIVE</h2>
      <p>ALL FOR FOOTBALL ‒ FOOTBALL FOR ALL!</p>
      <p>
        Even though Abaz, Kuan, Max Rock, and Walt all have different
        backgrounds, what makes them best friends is their passion for football!
        While they love their local heroes from "The Dragons" football club, the
        boys also have the ball skills that make them the talk of the town!
        Despite this, they are ordinary boys enjoying their childhood and school
        days.
      </p>
      <p>
        However, they still have dreams, and one of them is to be the best in
        the local TCL (Teenage City League). As they travel across the city and
        play against unique opponents as the "Golden Five," they will need your
        help to come on top and finally become the champions. Learn more about
        this exciting game!
      </p>
    </b-container>

    <b-container class="stores">
      <b-container>
        <div class="store">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://golden5.nswebdevelopment.com/"
            class="btn"
            >WEBSITE</a
          >
        </div>
      </b-container>
    </b-container>

    <b-container fluid class="details">
      <div class="container_1920">
        <b-row>
          <b-col cols="12" sm="12" md="12" xl="6" lg="6">
            <b-img fluid class="img_w" :src="phone1" alt="Golden Five"></b-img>
          </b-col>
          <b-col cols="12" sm="12" md="12" xl="6" lg="6" class="detail">
            <b-container>
              <h5><span>CLIENT</span></h5>
              <p>NSWD</p>
            </b-container>
            <b-container>
              <h5><span>SERVICE</span></h5>
              <p>Software Development, Mobile Development, SEO</p>
            </b-container>
            <b-container>
              <h5><span>TECHNOLOGIES</span></h5>
              <p>C#, HTML & CSS, XML, Java, Android</p>
            </b-container>
            <b-container>
              <h5><span>YEAR</span></h5>
              <p>2017</p>
            </b-container>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phone1: require("@/assets/img/web/golden5.webp"),
    };
  },
};
</script>
