<template>
  <div id="WebsiteDetails">
    <b-container class="description">
      <h5><span>WEBSITE</span></h5>
      <h2>PROMETHEUS</h2>
      <p>
        Prometheus has designed a fully functional MVP (Minimum Viable Product)
        that allows anyone to connect to the database through a few clicks. The
        user selects a table and column to transfer to a blockchain, chooses the
        type of blockchain (Prometheus, Ethereum, Cardano, Stellar, or others),
        and decides whether to execute the action only once or repetitively.
        Prometheus MVP can also employ a reverse process to move data from the
        blockchain to the database.
      </p>
      <p>
        Prometheus software enables the more straightforward implementation of
        blockchain technology. While building a blockchain is an expensive
        process, Prometheus solves that by helping companies efficiently and
        conveniently transfer the part of their business to the blockchain. This
        will dramatically benefit the business operations while making the
        organization more competitive. Learn more by visiting the web
        presentation.
      </p>
    </b-container>

    <b-container class="stores">
      <b-container>
        <div class="store">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://prometheus.business/"
            class="btn"
            >WEBSITE</a
          >
        </div>
      </b-container>
    </b-container>

    <b-container fluid class="details">
      <div class="container_1920">
        <b-row>
          <b-col cols="12" sm="12" md="12" xl="6" lg="6">
            <b-img fluid class="img_w" :src="phone1" alt="Prometheus"></b-img>
          </b-col>
          <b-col cols="12" sm="12" md="12" xl="6" lg="6" class="detail">
            <b-container>
              <h5><span>CLIENT</span></h5>
              <p>NSWD</p>
            </b-container>
            <b-container>
              <h5><span>SERVICE</span></h5>
              <p>Software Development, Mobile Development, SEO</p>
            </b-container>
            <b-container>
              <h5><span>TECHNOLOGIES</span></h5>
              <p>C#, HTML & CSS, XML</p>
            </b-container>
            <b-container>
              <h5><span>YEAR</span></h5>
              <p>2019</p>
            </b-container>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phone1: require("@/assets/img/web/prometheus.webp"),
    };
  },
};
</script>
