import Vue from "vue";
import App from "./App.vue";
import router from "./router/index.js";


//import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
//Vue.component("font-awesome-icon", FontAwesomeIcon);

import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
library.add(faUserSecret);  

import VueMeta from 'vue-meta';
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});

import VeeValidate from "vee-validate";
Vue.use(VeeValidate, {
  classes: true,
  classNames: {
    valid: "is-valid",
    invalid: "is-invalid"
  }
});

import { LMap, LTileLayer, LMarker, LIcon } from 'vue2-leaflet';
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-icon', LIcon);


import BootstrapVue from "bootstrap-vue";
Vue.use(BootstrapVue);

import VueAxios from "vue-axios";
import axios from "axios";
Vue.use(VueAxios, axios);

import { VueReCaptcha } from 'vue-recaptcha-v3'
Vue.use(VueReCaptcha, { siteKey: '6Ld2nhcaAAAAAEyE060c_7MTON_tXGrbA_nFl2Ph' }) //nswebdevelopment
//Vue.use(VueReCaptcha, { siteKey: ' 6LdcSigaAAAAAO-nNi0bCiZ702yIMxk-7_oLplpt' }) //nswebdevelopment

import VuePrlx from 'vue-prlx'
Vue.use(VuePrlx);

import VueTypedJs from 'vue-typed-js'
Vue.use(VueTypedJs);

Vue.config.productionTip = false;

import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);


new Vue({
  router,
  render: h => h(App)
}).$mount("#app");


