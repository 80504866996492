<template>
  <div id="GameDetails">
    <b-container class="description">
      <h5><span>GAME</span></h5>
      <h2>BATTLEMIST</h2>
      <p>
        Build your Army of Men and go into a battle against the Dark Legion of
        Orcs. Alternatively, seal your destiny as the Ruler of the Horde and
        lead them to victory! In September of 2015, this project was published
        as our first in-house project entirely done by our team. Experience ten
        extraordinary levels, apply numerous tower and unit upgrades, feast your
        eyes on stunning environments, and discover unique characters you'll
        lead in epic battles.
      </p>
    </b-container>

    <b-container class="stores">
      <b-container>
        <div class="store">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.youtube.com/watch?v=KjLg-igHeDI"
            class="btn"
            >TRAILER</a
          >
        </div>
        <div class="store">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://battlemist.nswebdevelopment.com/"
            class="btn"
            >WEBSITE</a
          >
        </div>
      </b-container>
      <b-container>
        <div class="store_img">
          <div class="store">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://apps.apple.com/us/app/battlemist-clash-of-towers/id1062219858"
            >
              <b-img
                :src="appstore"
                class="img-fluid"
                alt="Appstory logo"
              ></b-img>
            </a>
          </div>
          <div class="store">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://play.google.com/store/apps/details?id=com.casualgamestudio.battlemistclashoftowers"
            >
              <b-img
                :src="playstore"
                class="img-fluid"
                alt="Playstore logo"
              ></b-img>
            </a>
          </div>
        </div>
      </b-container>
    </b-container>

    <b-container fluid class="details">
      <div class="container_1920">
        <b-row>
          <b-col cols="12" sm="12" md="12" lg="6" xl="6" p-sm="0">
            <b-img
              fluid
              class="img_h"
              :src="battlemist"
              alt="Battlemist"
            ></b-img>
          </b-col>
          <b-col cols="12" sm="12" md="12" lg="6" xl="6" class="detail">
            <b-container>
              <h5><span>CLIENT</span></h5>
              <p>NSWD</p>
            </b-container>
            <b-container>
              <h5><span>GENRE</span></h5>
              <p>Mobile strategy game</p>
            </b-container>
            <b-container>
              <h5><span>SERVICES</span></h5>
              <p>Android, iOS and WebGL Development</p>
            </b-container>
            <b-container>
              <h5><span>TEHNOLOGIES</span></h5>
              <p>C#, Unity 2D/3D</p>
            </b-container>
            <b-container>
              <h5><span>YEAR</span></h5>
              <p>2014</p>
            </b-container>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      battlemist: require("@/assets/img/games/battlemist.webp"),
      appstore: require("@/assets/img/AppStore.webp"),
      playstore: require("@/assets/img/GooglePlay.webp"),
    };
  },
};
</script>
