<template>
  <div id="WebsiteDetails">
    <b-container class="description">
      <h5><span>WEBSITE</span></h5>
      <h2>TOP TENS</h2>
      <p>
        When you want to discover more about pop culture, sports, science,
        fashion, or anything else, why not do it in a fun way? On TopTens.fun,
        you'll find hundreds of original and entertaining top 10 lists that will
        help you acquire knowledge and even learn essential life hacks. You can
        feel the passion in every article, so you won't stay indifferent to
        whatever you are reading. Visitors are encouraged to leave their
        comments, and they'll be able to send their articles.
      </p>
      <p>
        TopTens.fun covers more than a dozen topics, including sports, video
        games, music, science, fashion, beauty, TV, movies, and much more. The
        list continually grows with new content published frequently, so make it
        a part of your everyday routine!
      </p>
    </b-container>

    <b-container class="stores">
      <b-container>
        <div class="store">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://toptens.fun/"
            class="btn"
            >WEBSITE</a
          >
        </div>
      </b-container>
    </b-container>

    <b-container fluid class="details">
      <div class="container_1920">
        <b-row>
          <b-col cols="12" sm="12" md="12" xl="6" lg="6">
            <b-img fluid class="img_w" :src="TopTens" alt="Top tens"></b-img>
          </b-col>
          <b-col cols="12" sm="12" md="12" xl="6" lg="6" class="detail">
            <b-container>
              <h5><span>CLIENT</span></h5>
              <p>NSWD</p>
            </b-container>
            <b-container>
              <h5><span>SERVICE</span></h5>
              <p>Design, Website Development, SEO</p>
            </b-container>
            <b-container>
              <h5><span>TECHNOLOGIES</span></h5>
              <p>Laravel, HTML & CSS, XML, Java, Android</p>
            </b-container>
            <b-container>
              <h5><span>YEAR</span></h5>
              <p>2020</p>
            </b-container>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      TopTens: require("@/assets/img/web/top-tens.webp"),
    };
  },
};
</script>
