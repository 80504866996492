<template>
  <b-container fluid class="p-0">
    <l-map :zoom="zoom" :center="center" class="mapContainer">
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      <l-marker :lat-lng="marker">
        <l-icon :icon-url="icon" />
      </l-marker>
    </l-map>
  </b-container>
</template>
<script>
import L from "leaflet";
export default {
  data() {
    return {
      zoom: 15,
      center: [45.2422804, 19.8437334],
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution: "Map data &copy; OpenStreetMap contributors",
      layer: new L.TileLayer(
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      ),
      marker: [45.2422804, 19.8437334],
      options: {
        position: "bottomleft",
        width: 200,
        height: 300,
      },
      icon: require("../assets/img/baseball-marker.png"),
    };
  },
};
</script>
<style>
.vue2leaflet-map {
  width: 100%;
  height: 50vh !important;
}
</style>