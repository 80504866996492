<template>
  <div id="PageContact">
    <b-container fluid class="bg-white">
      <b-container class="description">
        <h5><span>CONTACT US</span></h5>
        <h2>CONTACT IN CANADA</h2>
        <p>
          Feel free to contact us regarding our services or to pitch a project,
          as our customer service center works for you. Rest assured that we
          will quickly get back to you.
        </p>
      </b-container>
      <b-container>
        <h2>IGOR BJELAC</h2>
        <ul>
          <li>Secondary Graphic School</li>
          <li>The Faculty of Sport and Physical Education</li>
          <li>The founder and the vice president of Darts sport club</li>
          <li>
            The founder and director of the NGO Beehive, humanitarian
            not-for-profit society 2005-2015
          </li>
          <li>
            External associate of the SOS Children's Village, a Center for
            Social Work and Children's Safe House
          </li>
          <li>
            A member of the City Commission for Local Youth Action Plan(social
            work) of the city of Novi Sad, 2014
          </li>
          <li>
            Sales Manager at NS Web Development, 2015. Canada Representative
          </li>
        </ul>
      </b-container>
    </b-container>
    <AppContact></AppContact>
    <AppMaps></AppMaps>
  </div>
</template>

<script>
import AppContact from "./AppContact";
import AppMaps from "./AppMaps";

export default {
  components: {
    AppContact,
    AppMaps,
  },
};
</script>