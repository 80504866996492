<template>
  <div id="ApplicationD">
    <b-container class="description">
      <h5><span>APPLICATION</span></h5>
      <h2>MY SPOT</h2>
      <p>
        My Spot is perfect for cities with the traffic problem and a lack of available parking space. 
        Our solution is a geofencing platform that includes mobile apps utilizing all available 
        mobile phone sensors and deep learning algorithms for free parking space probability models. 
        Unlike the traditional "modern" approach with low parking accuracy, high maintenance efforts, 
        running cost, and other difficulties, Simula provides a platform that enables data flow within 
        the city area and simplifies the parking process.
      </p>
    </b-container>

    <b-container class="stores">
      <b-container>
        <div class="store">
          <a target="_blank" rel="noreferrer" href="https://myspotparknow.com/#top" class="btn">WEBSITE</a>
        </div>
      </b-container>
      <b-container>
        <div class="store_img">
        <div class="store">
          <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=biz.simula.myspotparknow&hl=sr" class="store">
            <b-img :src="playstory" alt="Playstory logo"></b-img>
          </a>
        </div>
        </div>
      </b-container>
    </b-container>

    <b-container fluid class="details">
      <div class="container_1920">
      <b-row>
        <b-col cols="12" sm="12" md="12" xl="6" lg="6">
          <b-img fluid class="img_w" :src="phone1" alt="My Spot"></b-img>
        </b-col>

        <b-col cols="12" sm="12" md="12" xl="6" lg="6" class="detail">
          <b-container>
            <h5><span>CLIENT</span></h5>
            <p>Simula, Serbia</p>
          </b-container>

          <b-container>
            <h5><span>SERVICES</span></h5>
            <p>Software Development, Mobile Development, SEO</p>
          </b-container>

          <b-container>
            <h5><span>TECHNOLOGIES</span></h5>
            <p>C#, HTML & CSS, XML, Java, Android</p>
          </b-container>
          <b-container>
            <h5><span>YEAR</span></h5>
            <p>2020</p>
          </b-container>

        </b-col>
      </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phone1: require("@/assets/img/aplication/my-spot.webp"),
      appstory: require("@/assets/img/AppStore.webp"),
      playstory: require("@/assets/img/GooglePlay.webp"),
    };
  },
};
</script>
