<template>
  <div id="PageCartoonsDetails">
    <b-container class="description">
      <h5><span>CARTOON</span></h5>
      <h2>THE ORGANISM HEROES</h2>
      <p>
        This animated series will try to explain the most important processes in
        the human organism in the twelve eleven-minute episodes. The main
        characters are Captain Blue, Captain Red, and Captain White. Captain
        Blue is a brain cell that coordinates every action, Captain Red controls
        erythrocytes, and Captain White regulates leukocytes. At the beginning
        of each episode, something unpleasant happens to the boy called Nick,
        including injuries or a reaction to some external effect. Luckily, our
        characters, our captains, get on the scene and solve the problem through
        actions while giving explanations. They are an endless source of
        knowledge and interesting educational information, but they are also
        highly sympathetic.
      </p>
      <p>
        The first episode is devoted to the bloodstream and heart. Through a
        series of illustrations and sketches, the child will get familiar with
        the appearance, the role, and the heart's functioning, yet the emphasis
        is not placed only on the theory. Through the game of our main
        characters, the idea is to introduce a child to the world of the human
        organism. This way, kids can acquire knowledge, not even knowing that
        they are learning. The goal is to study through the game and visualize
        all abstract concepts. The Organism Heroes makes the world closer to
        children while waking up their interest and curiosity. Only a curious
        child can shift the boundaries of its mind as well as the mind of this
        universe.
      </p>
    </b-container>

    <b-container class="stores">
      <b-container>
        <div class="store">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.youtube.com/watch?v=KhdOQlnfaow&ab_channel=NSWebDevelopment"
            class="btn"
            >TRAILER</a
          >
        </div>
        <div class="store">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://wolfmedia.media/"
            class="btn"
            >WEBSITE</a
          >
        </div>
      </b-container>
    </b-container>

    <b-container fluid class="details">
      <div class="container_1920">
        <b-row>
          <b-col cols="12" sm="12" md="12" lg="6" xl="6">
            <b-img
              fluid
              class="img_w"
              :src="goldenfive"
              alt="The Organism Heroes"
            ></b-img>
          </b-col>
          <b-col cols="12" sm="12" md="12" lg="6" xl="6" class="detail">
            <b-container>
              <h5><span>PRODUCTION</span></h5>
              <p>NSWD</p>
            </b-container>
            <b-container>
              <h5><span>GENRE</span></h5>
              <p>Sports animatied series</p>
            </b-container>
            <b-container>
              <h5><span>DURATION</span></h5>
              <p>2 seasons 13 ep x 5min</p>
            </b-container>
            <b-container>
              <h5><span>YEAR</span></h5>
              <p>2017</p>
            </b-container>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      goldenfive: require("@/assets/img/Cartoons/The-Organism-Heroes.webp"),
    };
  },
};
</script>
