<template>
  <div id="ApplicationD">
    <b-container class="description">
      <h5><span>APPLICATION</span></h5>
      <h2>MACHINE CAN SEE</h2>
      <p>
        Machine Can See uses Artificial Intelligence and Computer Vision to create solutions that 
        help improve retail, transportation, and video surveillance efficiency. MCS offers Computer 
        Vision solutions for partners and communities, solving their unique and everyday retail, 
        transportation and safety needs.
      </p>
    </b-container>

    <div class="suportby">
      <h2>SUPPORTED BY</h2>
      <div class="logo-img">
        <a target="_blank" rel="noreferrer" href="http://www.mpn.gov.rs/">
          <b-img :src="ministrylogo" alt="Ministarstvo prosvete, nauke i tehnoloskog razvoja"></b-img>
        </a>
        <a target="_blank" rel="noreferrer" href="https://inovacije.gov.rs/">
          <b-img :src="ministryoffice" alt="Kabinet za inovacije i tehnoloski razvoj"></b-img>
        </a>
        <a target="_blank" rel="noreferrer" href="http://www.inovacionifond.rs/">
          <b-img :src="inovation" alt="Fond za inovacionu delatnost"></b-img>
        </a>
        <a target="_blank" rel="noreferrer" href="https://rsjp.gov.rs/en/about-us/">
          <b-img :src="government" alt="Government of the Republic of Serbia"></b-img>
        </a>
        <a target="_blank" rel="noreferrer" href="https://www.worldbank.org/">
          <b-img :src="worldbanklogo" alt="The World bank"></b-img>
        </a>
      </div>
    </div>

    <b-container class="stores">
      <b-row>
        <b-col sm="8" md="4" xl="2" offset-sm="2" offset-md="4" offset-xl="5">
        </b-col>
      </b-row>
      <b-container>
        <div class="store">
          <a target="_blank" rel="noreferrer" href="https://www.machinecansee.com/" class="btn"
            >WEBSITE</a
          >
        </div>
      </b-container>
    </b-container>

    <b-container fluid class="details">
      <div class="container_1920">
        <b-row>
          <b-col cols="12" sm="12" md="12" xl="6" lg="6">
            <div class="star">
                  <b-img alt="Time tracker" fluid :src="star"></b-img>
                </div>
            <b-img fluid class="img_w" :src="machincansee" alt="Machine Can See"></b-img>
          </b-col>
          <b-col cols="12" sm="12" md="12" xl="6" lg="6" class="detail">
            <b-container>
              <h5><span>CLIENT</span></h5>
              <p>Machine Can See LLC, Serbia</p>
            </b-container>
            <b-container>
              <h5><span>SERVICES</span></h5>
              <p>Software Development, SEO</p>
            </b-container>
            <b-container>
              <h5><span>TECHNOLOGIES</span></h5>
              <p>Wordpress</p>
            </b-container>
            <b-container>
              <h5><span>YEAR</span></h5>
              <p>2020</p>
            </b-container>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      machincansee: require("@/assets/img/aplication/machine-can-see.webp"),
      appstory: require("@/assets/img/AppStore.webp"),
      playstory: require("@/assets/img/GooglePlay.webp"),
      ministrylogo: require("@/assets/img/Logos/ministry-logo.webp"),
      ministryoffice: require("@/assets/img/Logos/office-logo.webp"),
      inovation: require("@/assets/img/Logos/innovation-fund-logo.webp"),
      government: require("@/assets/img/Logos/government-logo.webp"),
      worldbanklogo: require("@/assets/img/Logos/world-bank-logo.webp"),
      star: require("@/assets/img/star-solid.webp"),
    };
  },
};
</script>
