<template>
  <div id="WebsiteDetails">
    <b-container class="description">
      <h5><span>WEBSITE</span></h5>
      <h2>TRULY PUBLIC</h2>
      <p>
        Truly Public is a crypto platform that brings crypto database data to a
        broad audience by exporting it to formats understandable by various
        business software. Currently, supported formats include Excel and
        MongoDB.
      </p>
      <p>
        By exporting data to business & scientific tools and relational
        databases, Truly Public makes it available to billions of users. Thus,
        it promotes the general use of cryptocurrencies, as it is not dependant
        on any of them. Visit the site for more information about the product
        and its implementation.
      </p>
    </b-container>

    <b-container class="stores">
      <b-container>
        <div class="store">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://trulypublic.com/"
            class="btn"
            >WEBSITE</a
          >
        </div>
      </b-container>
    </b-container>

    <b-container fluid class="details">
      <div class="container_1920">
        <b-row>
          <b-col cols="12" sm="12" md="12" xl="6" lg="6">
            <b-img fluid class="img_w" :src="phone1" alt="Truly public"></b-img>
          </b-col>
          <b-col cols="12" sm="12" md="12" xl="6" lg="6" class="detail">
            <b-container>
              <h5><span>CLIENT</span></h5>
              <p>NSWD</p>
            </b-container>
            <b-container>
              <h5><span>SERVICE</span></h5>
              <p>Software Development, Mobile Development, SEO</p>
            </b-container>
            <b-container>
              <h5><span>TECHNOLOGIES</span></h5>
              <p>C#, HTML & CSS, XML</p>
            </b-container>
            <b-container>
              <h5><span>YEAR</span></h5>
              <p>2019</p>
            </b-container>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phone1: require("@/assets/img/web/truly-public.webp"),
    };
  },
};
</script>
