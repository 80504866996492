<template>
  <div id="PageCartoons">
    <b-container class="description">
      <h5><span>CARTOONS</span></h5>
      <h2>ANIMATED SERIES</h2>
      <p>
        Only people who keep a child alive in themself can create amazing
        cartoons. That's why our skilled animators come together as a team that
        creates and will continue creating great entertainment products.
      </p>
    </b-container>

    <b-container fluid class="p-0">
      <b-container class="products">
        <b-row>
          <b-col
            cols="6"
            sm="6"
            md="6"
            lg="6"
            xl="6"
            @mouseover="play('heroes')"
            @mouseout="stop('heroes')"
            @focus="play('heroes')"
          >
            <div class="img-box">
              <router-link to="/cartoons/the-organism-heroes">
                <b-img
                  fluid
                  :src="Animations1"
                  alt="The Organism Heroes"
                ></b-img>
                <div class="video">
                  <video id="heroes" width="100%" height="auto" loop>
                    <source :src="TheOrganismHeroes" type="video/mp4" />
                  </video>
                </div>
              </router-link>
            </div>
          </b-col>
          <b-col
            cols="6"
            sm="6"
            md="6"
            lg="6"
            xl="6"
            @mouseover="play('tomtommy')"
            @mouseout="stop('tomtommy')"
            @focus="play('tomtommy')"
          >
            <div class="img-box">
              <router-link to="/cartoons/tom-and-tommy">
                <b-img fluid :src="Animations2" alt="Tom Tommy"></b-img>
                <div class="video">
                  <video id="tomtommy" width="100%" height="auto" loop>
                    <source :src="TomTommy" type="video/mp4" />
                  </video>
                </div>
              </router-link>
            </div>
          </b-col>
          <b-col
            cols="6"
            sm="6"
            md="6"
            lg="6"
            xl="6"
            @mouseover="play('captainpepe')"
            @mouseout="stop('captainpepe')"
          >
            <div class="img-box">
              <router-link to="/cartoons/captain-pepe">
                <b-img fluid :src="Animations3" alt="Captain Pepe"></b-img>
                <div class="video">
                  <video id="captainpepe" width="100%" height="auto" loop>
                    <source :src="CaptainPepe" type="video/mp4" />
                  </video>
                </div>
              </router-link>
            </div>
          </b-col>
          <b-col
            cols="6"
            sm="6"
            md="6"
            lg="6"
            xl="6"
            @mouseover="play('paladin')"
            @mouseout="stop('paladin')"
          >
            <div class="img-box">
              <router-link to="/cartoons/golden-five">
                <b-img fluid :src="Animations4" alt="Golden five"></b-img>
                <div class="video">
                  <video id="paladin" width="100%" height="auto" loop>
                    <source :src="GoldenFive" type="video/mp4" />
                  </video>
                </div>
              </router-link>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      Animations1: require("@/assets/img/Cartoons/The-Organism-Heroes.webp"),
      Animations2: require("@/assets/img/Cartoons/Tom-&-Tommy.webp"),
      Animations3: require("@/assets/img/Cartoons/Captain-Pepe.webp"),
      Animations4: require("@/assets/img/Cartoons/Golden-5.webp"),
      TomTommy: require("@/assets/video/TomTommy.mp4"),
      CaptainPepe: require("@/assets/video/CaptainPepe.mp4"),
      GoldenFive: require("@/assets/video/GoldenFive.mp4"),
      TheOrganismHeroes: require("@/assets/video/TheOrganismHeroes.mp4"),
      tomImg: true,
    };
  },
  methods: {
    play(id) {
      this.tomImg = false;
      var tomtommy = document.getElementById(id);
      tomtommy.load();
      tomtommy.play();
    },
    stop(id) {
      this.tomImg = true;
      document.getElementById(id).pause();
    },
  },
};
</script>
